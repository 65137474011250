import {collection, getDocs, orderBy, query, where} from 'firebase/firestore'
import Moment from 'react-moment'
import React, {FC, useEffect, useRef, useState} from 'react'
import Shipment from '../../../../app/models/Shipment'
import {fbsdb} from '../../../../app/utils/firebase'
import {SearchComponent} from '../../../assets/ts/components'
import {KTSVG} from '../../../helpers'
import {Link} from 'react-router-dom'

const shipmentDB = 'shipments'

const Search: FC = () => {
  const [menuState, setMenuState] = useState<'main' | 'advanced' | 'preferences'>('main')
  const element = useRef<HTMLDivElement | null>(null)
  const wrapperElement = useRef<HTMLDivElement | null>(null)
  const resultsElement = useRef<HTMLDivElement | null>(null)
  // const suggestionsElement = useRef<HTMLDivElement | null>(null)
  const emptyElement = useRef<HTMLDivElement | null>(null)
  const searching = useRef(0)

  const init: Shipment[] = []
  const [shipments, setShipments] = useState(init)

  const processs = async (search: SearchComponent) => {
    searching.current++
    const myTimeout = setTimeout(async () => {
      searching.current--

      if (searching.current === 0) {
        let olist: any[] = []

        const key = search.inputElement.value.toUpperCase()
        const q = await getDocs(
          query(
            collection(fbsdb, shipmentDB),
            where('code', '==', key),
            orderBy('dateCreated', 'desc')
          )
        )

        const objList: any[] = q.docs.map((doc: any) => {
          const obj = doc.data() as any
          obj.id = doc.id

          return obj
        })

        olist = objList

        if (!isNaN(parseInt(key))) {
          const q2 = await getDocs(
            query(
              collection(fbsdb, shipmentDB),
              where('identity', '==', parseInt(key)),
              orderBy('dateCreated', 'desc')
            )
          )

          const objList2: any[] = q2.docs.map((doc: any) => {
            const obj = doc.data() as any
            obj.id = doc.id

            return obj
          })

          olist = olist.concat(objList2)
        }

        setShipments(olist)
        if (olist.length > 0) {
          // Show results
          resultsElement.current!.classList.remove('d-none')
          // Hide empty message
          emptyElement.current!.classList.add('d-none')
        } else {
          // Hide results
          resultsElement.current!.classList.add('d-none')
          // Show empty message
          emptyElement.current!.classList.remove('d-none')
        }

        search.complete()
      }

      clearTimeout(myTimeout)
    }, 350)
  }

  const clear = (search: SearchComponent) => {
    // Show recently viewed
    // suggestionsElement.current!.classList.remove('d-none')
    // Hide results
    resultsElement.current!.classList.add('d-none')
    // Hide empty message
    emptyElement.current!.classList.add('d-none')
  }

  useEffect(() => {
    // Initialize search handler
    const searchObject = SearchComponent.createInsance('#kt_header_search')
    // Search handler
    searchObject!.on('kt.search.process', processs)

    // Clear handler
    searchObject!.on('kt.search.clear', clear)
  }, [])

  return (
    <>
      <div
        id='kt_header_search'
        className='d-flex align-items-stretch'
        data-kt-search-keypress='true'
        data-kt-search-min-length='2'
        data-kt-search-enter='enter'
        data-kt-search-layout='menu'
        data-kt-menu-trigger='auto'
        data-kt-menu-overflow='false'
        data-kt-menu-permanent='true'
        data-kt-menu-placement='bottom-end'
        ref={element}
      >
        <div
          className='d-flex align-items-center'
          data-kt-search-element='toggle'
          id='kt_header_search_toggle'
        >
          <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
            <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1' />
          </div>
        </div>

        <div
          data-kt-search-element='content'
          className='menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px'
        >
          <div
            className={`${menuState === 'main' ? '' : 'd-none'}`}
            ref={wrapperElement}
            data-kt-search-element='wrapper'
          >
            <form
              onSubmit={(e) => {
                e.preventDefault()
              }}
              data-kt-search-element='form'
              className='w-100 position-relative mb-3'
              autoComplete='off'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
              />

              <input
                type='text'
                className='form-control form-control-flush ps-10'
                name='search'
                placeholder='Search Barcode / Indentifier'
                data-kt-search-element='input'
              />

              <span
                className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1'
                data-kt-search-element='spinner'
              >
                <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
              </span>

              <span
                className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none'
                data-kt-search-element='clear'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 svg-icon-lg-1 me-0'
                />
              </span>

              <div
                className='position-absolute top-50 end-0 translate-middle-y'
                data-kt-search-element='toolbar'
              >
                <div
                  data-kt-search-element='preferences-show'
                  className='btn btn-icon w-20px btn-sm btn-active-color-primary me-1'
                  data-bs-toggle='tooltip'
                  onClick={() => {
                    setMenuState('preferences')
                  }}
                  title='Show search preferences'
                >
                  <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-1' />
                </div>

                {/* <div
                  data-kt-search-element='advanced-options-form-show'
                  className='btn btn-icon w-20px btn-sm btn-active-color-primary'
                  data-bs-toggle='tooltip'
                  onClick={() => {
                    setMenuState('advanced')
                  }}
                  title='Show more search options'
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-2' />
                </div> */}
              </div>
            </form>

            <div ref={resultsElement} data-kt-search-element='results' className='d-none'>
              <div className='scroll-y mh-200px mh-lg-350px'>
                <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
                  Shipments
                </h3>

                {shipments.map((shipment) => (
                  <Link
                    key={shipment.id}
                    to={`/shipment/${shipment.id}/view`}
                    className='d-flex text-dark text-hover-primary align-items-center mb-5'
                  >
                    {/* <div className='symbol symbol-40px me-4'>
                      <img src={toAbsoluteUrl('/media/avatars/300-6.jpg')} alt='' />
                    </div> */}

                    <div className='d-flex flex-column justify-content-start fw-bold'>
                      <span className='fs-6 fw-bold'>
                        {shipment.code}
                        {' | '}
                        <Moment format='DD/MM/YYYY'>{shipment.dateCreated.toDate()}</Moment>
                      </span>
                      {shipment.status === 'completed' && (
                        <span className='fs-7 fw-bold text-success'>Delivered</span>
                      )}
                      {shipment.status === 'problem' && (
                        <span className='fs-7 fw-bold text-danger'>Problem Shipment</span>
                      )}
                      {shipment.status === 'checked_in' && (
                        <span className='fs-7 fw-bold text-warning'>Checked In</span>
                      )}
                      {shipment.status === 'out_for_delivery' && (
                        <span className='fs-7 fw-bold text-primary'>Out For Delivery</span>
                      )}
                      {shipment.status === 'returned' && (
                        <span className='fs-7 fw-bold text-muted'>Returned</span>
                      )}
                      {shipment.status === 'achieved' && (
                        <span className='fs-7 fw-bold text-muted'>Achieved</span>
                      )}
                    </div>
                  </Link>
                ))}
              </div>
            </div>

            <div ref={emptyElement} data-kt-search-element='empty' className='text-center d-none'>
              <div className='pt-10 pb-10'>
                <KTSVG
                  path='/media/icons/duotune/files/fil024.svg'
                  className='svg-icon-4x opacity-50'
                />
              </div>

              <div className='pb-15 fw-bold'>
                <h3 className='text-gray-600 fs-5 mb-2'>No result found</h3>
                <div className='text-muted fs-7'>Please try again with a different query</div>
              </div>
            </div>
          </div>

          <form className={`pt-1 ${menuState === 'preferences' ? '' : 'd-none'}`}>
            <h3 className='fw-bold text-dark mb-7'>Search Preferences</h3>

            <div className='pb-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Barcode
                </span>

                <input
                  className='form-check-input'
                  type='checkbox'
                  value='1'
                  disabled
                  defaultChecked
                />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Indentifier
                </span>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value='1'
                  defaultChecked
                  disabled
                />
              </label>
            </div>

            <div className='d-flex justify-content-end pt-7'>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setMenuState('main')
                }}
                className='btn btn-sm btn-light fw-bolder btn-active-light-primary me-2'
              >
                Back
              </button>
              {/* <button className='btn btn-sm fw-bolder btn-primary'>Save Changes</button> */}
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export {Search}
