import {useEffect, useMemo, useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {collection, getDocs, orderBy, query, where} from 'firebase/firestore'
import {
  Alert,
  Badge,
  Button,
  Col,
  Form,
  InputGroup,
  ProgressBar,
  Row,
  Spinner,
} from 'react-bootstrap'
import React from 'react'
import Moment from 'react-moment'
import {KTSVG, useDebounce} from '../../../_metronic/helpers'
import {fbsdb} from '../../utils/firebase'
import moment from 'moment-timezone'
import CustomPagination from '../user-management/users-list/Pagination'
import {ExportToCsv} from 'export-to-csv'
import Shipment from '../../models/Shipment'

const shipmentDB = 'shipments'
const PageSize = 50
const organizationDB = 'organizations'
const groupDB = 'groups'

const ShipmentList: React.FC = () => {
  const navigate = useNavigate()
  const init: any[] = []
  const [pods, setPods] = useState(init)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(init)
  const raws = useRef(init)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const [alert, setAlert] = useState({isAlert: false, msg: '', variant: 'primary'})
  const [isExporting, setIsExporting] = useState(false)
  const [exportProgress, setExportProgress] = useState(0)
  const [startDate, setStartDate] = useState<string>(
    moment().add(-1, 'day').tz('Asia/Singapore').format('YYYY-MM-DD')
  )
  const [endDate, setEndDate] = useState<string>(moment().tz('Asia/Singapore').format('YYYY-MM-DD'))
  const [organizations, setOrganizations] = useState(init)
  const [groups, setGroups] = useState(init)
  const [selectedOrganization, setSelectedOrganization] = useState('')
  const [selectedGroup, setSelectedGroup] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('')

  const dataField = useRef({
    startDate: new Date(),
    endDate: new Date(),
    supplierId: '',
    groupId: '',
    status: '',
  })

  useEffect(() => {
    const sd = moment(startDate, 'YYYY-MM-DD').add(-1, 'day').tz('Asia/Singapore').toDate()
    const ed = moment(endDate, 'YYYY-MM-DD').add(1, 'day').tz('Asia/Singapore').toDate()

    loadOrganizations()
    loadShipmentList(sd, ed, '', '')
  }, [])

  useEffect(() => {
    loadGroups()
  }, [selectedOrganization])

  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        filterShipments()
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )

  const loadShipmentList = async (
    startDate: Date,
    endDate: Date,
    supplierId: string,
    groupId: string
  ) => {
    setIsLoading(true)
    let q
    if (groupId && supplierId) {
      q = await getDocs(
        query(
          collection(fbsdb, shipmentDB),
          where('fromOrganizationId', '==', supplierId),
          where('fromOrganizationGroupId', '==', groupId),
          where('checkinDateTime', '>=', startDate),
          where('checkinDateTime', '<', endDate),
          orderBy('checkinDateTime', 'desc')
        )
      )
    } else if (supplierId) {
      q = await getDocs(
        query(
          collection(fbsdb, shipmentDB),
          where('fromOrganizationId', '==', supplierId),
          where('checkinDateTime', '>=', startDate),
          where('checkinDateTime', '<', endDate),
          orderBy('checkinDateTime', 'desc')
        )
      )
    } else {
      q = await getDocs(
        query(
          collection(fbsdb, shipmentDB),
          where('checkinDateTime', '>=', startDate),
          where('checkinDateTime', '<', endDate),
          orderBy('checkinDateTime', 'desc')
        )
      )
    }
    const objList: any[] = q.docs.map((doc: any) => {
      const obj = doc.data() as any
      obj.id = doc.id

      return obj
    })

    raws.current = objList
    filterShipments()
    //setData(objList)
  }

  const loadOrganizations = async () => {
    const q = await getDocs(query(collection(fbsdb, organizationDB)))
    const objList: any[] = q.docs.map((doc: any) => {
      const obj = doc.data() as any
      obj.id = doc.id

      return obj
    })

    objList.sort((a, b) => a.name.localeCompare(b.name))

    setOrganizations(objList)

    if (dataField.current.supplierId) {
      setSelectedOrganization(dataField.current.supplierId)
    }
  }

  const loadGroups = async () => {
    if (selectedOrganization) {
      const q = await getDocs(
        query(collection(fbsdb, organizationDB + '/' + selectedOrganization + '/' + groupDB))
      )
      const objList: any[] = q.docs.map((doc: any) => {
        const obj = doc.data() as any
        obj.id = doc.id

        return obj
      })

      objList.sort((a, b) => a.name.localeCompare(b.name))

      setGroups(objList)

      if (dataField.current.groupId) {
        setSelectedGroup(dataField.current.groupId)
      }
    }
  }

  const filterShipments = () => {
    setIsLoading(true)
    let fd = raws.current
    if (debouncedSearchTerm) {
      fd = fd.filter((s) => {
        return (
          s.code.toLowerCase().indexOf(debouncedSearchTerm.toLowerCase()) > -1 ||
          s.podByName.toLowerCase().indexOf(debouncedSearchTerm.toLowerCase()) > -1
        )
      })
    }

    if (dataField.current.status !== '') {
      fd = fd.filter((s) => s.status === dataField.current.status)
    }

    setData(fd)
    setIsLoading(false)
  }

  const startDateHandler = (event: any) => {
    setStartDate(event.target.value)
  }

  const endDateHandler = (event: any) => {
    setEndDate(event.target.value)
  }

  const supplierChangeHandler = (event: any) => {
    setSelectedGroup('')

    setSelectedOrganization(event.target.value)
  }

  const groupChangeHandler = (event: any) => {
    setSelectedGroup(event.target.value)
  }

  const searchHandler = () => {
    const sd = moment(startDate, 'YYYY-MM-DD').tz('Asia/Singapore').toDate()
    const ed = moment(endDate, 'YYYY-MM-DD').add(1, 'day').tz('Asia/Singapore').toDate()

    loadShipmentList(sd, ed, '', '')
  }

  const applyHandler = () => {
    // if (selectedOrganization === '') {
    //   setAlert({isAlert: true, msg: 'Please select a supplier group', variant: 'danger'})
    //   return
    // }

    const sd = moment(startDate, 'YYYY-MM-DD').tz('Asia/Singapore').toDate()
    const ed = moment(endDate, 'YYYY-MM-DD').add(1, 'day').tz('Asia/Singapore').toDate()

    loadShipmentList(sd, ed, selectedOrganization, selectedGroup)
  }

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    setPods(data.slice(firstPageIndex, lastPageIndex))
    setIsLoading(false)
  }, [currentPage, data])

  const exportHandler = async () => {
    setExportProgress(10)
    setIsExporting(true)

    let exportFile = `shipments_${startDate}_${endDate}`
    // const sd = moment(startDate, 'YYYY-MM-DD').tz('Asia/Singapore').toDate()
    // const ed = moment(endDate, 'YYYY-MM-DD').add(1, 'day').tz('Asia/Singapore').toDate()

    setExportProgress(25)

    const objList: any[] = data.map((doc: Shipment) => {
      let status = ''
      switch (doc.status) {
        case 'checked_in':
          status = 'Checked In'
          break
        case 'out_for_delivery':
          status = 'Out For Delivery'
          break
        case 'completed':
          status = 'Delivered'
          break
        case 'problem':
          status = 'Problem Shipment'
          break
        case 'achieved':
          status = 'Achieved'
          break
        default:
          status = ''
      }

      const obj = doc
      const newObj = {
        Barcode: obj.code,
        Status: status,
        Supplier: obj.fromOrganizationName,
        'Sub Group': obj.fromOrganizationGroupName,
        'From Station': obj.fromStationName,
        'Date Created': moment(obj.dateCreated.toDate())
          .tz('Asia/Singapore')
          .format('YYYY-MM-DD HH:mm:ss'),
        'Check In Date': moment(obj.checkinDateTime.toDate())
          .tz('Asia/Singapore')
          .format('YYYY-MM-DD'),
        'Check In Time': moment(obj.checkinDateTime.toDate())
          .tz('Asia/Singapore')
          .format('HH:mm:ss'),
        'Check In Trip': obj.checkinTrip === 1 ? '1st Trip' : '2nd Trip',
        'Check In By': obj.checkinByName,
        'Runsheet Date': obj.runsheetDate ? obj.runsheetDate : '',
        'Runsheet Trip': obj.runsheetTrip ? obj.runsheetTrip : '',
        'Runsheet Assigned By': obj.assignedByName ? obj.assignedByName : '',
        'Out for Delivery Date': obj.runsheetDateCreated
          ? moment(obj.runsheetDateCreated.toDate())
              .tz('Asia/Singapore')
              .format('YYYY-MM-DD HH:mm:ss')
          : '',
        'Driver Name': obj.driverName ? obj.driverName : '',
        'Area Code': obj.areaCode ? obj.areaCode : '',
        'ECP Name': obj.deliveryToName ? obj.deliveryToName : '',
        'POD Date': obj.podDate
          ? moment(obj.podDate.toDate()).tz('Asia/Singapore').format('YYYY-MM-DD HH:mm:ss')
          : '',
        'POD By': obj.podByName ? obj.podByName : '',
        'POD Status': obj.podStatus ? obj.podStatus : '',
        Identifier: obj.identity ? obj.identity : '',
        'Problem Date': obj.problemDate
          ? moment(obj.problemDate.toDate()).tz('Asia/Singapore').format('YYYY-MM-DD HH:mm:ss')
          : '',
        'Problem Code': obj.problemcode ? obj.problemcode : '',
        'Problem Description': obj.problemdesc ? obj.problemdesc : '',
        'Problem By': obj.problemByName ? obj.problemByName : '',
        // 'POD Proof': '',
      }

      return newObj
    })

    setExportProgress(75)

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      filename: exportFile,
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      //headers: headers,
    }

    const csvExporter = new ExportToCsv(options)

    csvExporter.generateCsv(objList)

    completeExport()
  }

  const completeExport = () => {
    setExportProgress(100)
    setTimeout(() => {
      setIsExporting(false)
      setExportProgress(0)
    }, 1000)
  }

  const statusChangeHandler = async (event: any) => {
    if (event.target.value === '') {
      // setAlert({isAlert: false, msg: '', variant: 'primary'})
      setSelectedStatus('')
    }

    dataField.current.status = event.target.value
    setSelectedStatus(event.target.value)

    filterShipments()
  }

  return (
    <>
      {alert.isAlert && (
        <Alert key='danger' variant={alert.variant}>
          {alert.msg}
        </Alert>
      )}
      <div className={`card mb-5 mb-xl-8`}>
        <div className='card-body py-3'>
          <h3 className='card-title align-items-start flex-column mt-5'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Selection {isLoading && <Spinner animation='border' variant='warning' size='sm' />}
            </span>
            <span className='text-muted mt-1 fw-semibold fs-7'></span>
          </h3>
          <Row>
            <Col md='5' className='mb-2'>
              <InputGroup className='mb-3'>
                <InputGroup.Text>Date Range</InputGroup.Text>
                <Form.Control
                  type='date'
                  onChange={startDateHandler}
                  value={startDate}
                  aria-label='Start date'
                />
                <Form.Control
                  type='date'
                  onChange={endDateHandler}
                  value={endDate}
                  aria-label='End date'
                />
              </InputGroup>
            </Col>
            <Col md='3' className='mb-2'>
              <Form.Select
                className='mb-3'
                aria-label='Supplier'
                onChange={supplierChangeHandler}
                value={selectedOrganization}
              >
                <option value=''>All Supplier Groups</option>
                {organizations.map((org) => (
                  <option key={org.id} value={org.id}>
                    {org.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md='3' className='mb-2'>
              <Form.Select
                className='mb-3'
                disabled={selectedOrganization === ''}
                aria-label='Supplier Group'
                onChange={groupChangeHandler}
                value={selectedGroup}
              >
                <option value={''}>All Suppliers</option>
                {groups.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md='1' className='mt-1 mb-3'>
              <Button onClick={applyHandler} className='mb-3 btn btn-sm btn-primary'>
                Apply
              </Button>
            </Col>
          </Row>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Filter {isLoading && <Spinner animation='border' variant='warning' size='sm' />}
            </span>
            <span className='text-muted mt-1 fw-semibold fs-7'></span>
          </h3>
          <Row>
            <Col md='3' className='mt-1 mb-3'>
              <Form.Select
                className='mb-3'
                aria-label='Status'
                onChange={statusChangeHandler}
                value={selectedStatus}
              >
                <option value={''}>All Status</option>
                <option value={'checked_in'}>Checked In</option>
                <option value={'out_for_delivery'}>Out For Delivery</option>
                <option value={'problem'}>Problem Shipment</option>
                <option value={'completed'}>Delivered</option>
                <option value={'achieved'}>Archieved</option>
              </Form.Select>
            </Col>
            {/* <Col md='3' className='mt-1 mb-3'>
              <Form.Select
                className='mb-3'
                aria-label='Area Code'
                onChange={areaChangeHandler}
                value={selectedArea}
              >
                <option value={''}>All Area</option>
              </Form.Select>
            </Col> */}
            {/* <Col md='4' className='mt-1 mb-3'>
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid me-3'
                placeholder='Filter by Outlet / ECP Name'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Col> */}
            <Col md='4' className='mt-1 mb-3'>
              <Button
                disabled={isExporting || data.length === 0}
                onClick={exportHandler}
                className='btn btn-sm btn-info ms-2 mb-3'
              >
                {isExporting ? 'Exporting..' : 'CSV Export'}
              </Button>
            </Col>
          </Row>
          {isExporting && (
            <Row>
              <Col md='10' className='mb-3'>
                <ProgressBar variant='info' animated now={exportProgress} />
              </Col>
            </Row>
          )}
        </div>
      </div>
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>
                    CODE{' '}
                    <Badge className='ms-2' bg='primary'>
                      Count: {data.length}
                    </Badge>{' '}
                  </th>
                  <th className='min-w-150px'>Status</th>
                  <th className='min-w-140px'>Check In</th>
                  <th className='min-w-140px'>Runsheet</th>
                  <th className='min-w-140px'>POD / Problem</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {pods.map((shipment) => (
                  <tr key={shipment.id}>
                    <td>
                      <Link
                        to={`/shipment/${shipment.id}/view`}
                        className='text-dark fw-bold text-hover-primary d-block fs-6'
                      >
                        {shipment.code}
                      </Link>
                    </td>
                    <td>
                      {shipment.status === 'checked_in' && <Badge bg='warning'>Checked In</Badge>}
                      {shipment.status === 'out_for_delivery' && (
                        <Badge bg='primary'>Out For Delivery</Badge>
                      )}
                      {shipment.status === 'problem' && (
                        <Badge bg='danger'>Problem: {shipment.problemcode}</Badge>
                      )}
                      {shipment.status === 'completed' && <Badge bg='success'>Delivered</Badge>}
                      {shipment.status === 'achieved' && <Badge bg='secondary'>Achieved</Badge>}
                    </td>
                    <td>
                      <div className='text-dark fw-bold d-block'>
                        <div>
                          <Moment format='DD/MM/YYYY HH:mm:ss'>
                            {shipment.checkinDateTime.toDate()}
                          </Moment>{' '}
                          ({shipment.checkinTrip})
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='text-dark fw-bold d-block'>
                        {shipment.runsheetDateCreated && (
                          <div>
                            <Moment format='DD/MM/YYYY HH:mm:ss'>
                              {shipment.runsheetDateCreated.toDate()}
                            </Moment>{' '}
                            ({shipment.runsheetTrip})
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className='text-dark fw-bold d-block'>
                        {shipment.problemDate && (
                          <div>
                            <Moment format='DD/MM/YYYY HH:mm:ss'>
                              {shipment.problemDate.toDate()}
                            </Moment>
                          </div>
                        )}
                        {shipment.podDate && (
                          <div>
                            <Moment format='DD/MM/YYYY HH:mm:ss'>
                              {shipment.podDate.toDate()}
                            </Moment>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
            <CustomPagination
              currentPage={currentPage}
              totalCount={data.length}
              pageSize={PageSize}
              onPageChange={(page: number) => setCurrentPage(page)}
            />
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default ShipmentList
