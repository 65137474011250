import {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {fbsdb} from '../../utils/firebase'
import {addDoc, collection, doc, getDocs, onSnapshot, query, updateDoc} from 'firebase/firestore'
import Shop from '../../models/Shop'
import {Alert, Form} from 'react-bootstrap'

const shopSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  address: Yup.string(),
  areaId: Yup.string().required('Area is required'),
})
const shopDB = 'shops'
const areaDB = 'delivery_areas'
const organizationDB = 'organizations'

const ECPManagement: React.FC = () => {
  const params = useParams()
  const eid = params.id ? params.id.toString() : undefined
  const init: any[] = []
  const [areas, setAreas] = useState(init)
  const [organizations, setOrganizations] = useState(init)
  const [alert, setAlert] = useState({isAlert: false, msg: '', variant: 'primary'})

  const initialValues: Shop = {
    name: '',
    areaId: '',
    address: '',
    cityId: '',
    cityName: '',
    stateId: '',
    stateName: '',
  }

  const [data, setData] = useState<Shop>(initialValues)
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)

  const formik = useFormik<Shop>({
    initialValues,
    validationSchema: shopSchema,
    onSubmit: (values) => {
      setLoading(true)
      setAlert({isAlert: false, msg: '', variant: 'primary'})
      // values.dateCreated = new Date()
      const updatedData = Object.assign(data, values)
      const orgList: any[] = []
      const orgIdList: string[] = []
      organizations.forEach((e) => {
        if (e.isSelected) {
          orgList.push({id: e.id, name: e.name})
          orgIdList.push(e.id)
        }
      })

      const seArea = areas.find((e) => e.id === updatedData.areaId)
      if (seArea) {
        updatedData.areaCode = seArea.areacode
        updatedData.organizations = orgList
        updatedData.organizationIds = orgIdList
        updatedData.state = seArea.state
        setData(updatedData)
        if (editMode) {
          editShop(updatedData)
        } else {
          addShop(updatedData)
        }
      }
      setLoading(false)
    },
  })

  const updateData = (fieldsToUpdate: Partial<Shop>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  useEffect(() => {
    if (eid) {
      setEditMode(true)
      const doDocRef = doc(fbsdb, shopDB, eid)
      onSnapshot(doDocRef, (doc) => {
        const shop = doc.data() as any
        shop.id = eid
        updateData(shop)
        // formik.setValues(usr)
        formik.setValues(data)
      })
    }
    loadAreas()
    loadOrganizations()
  }, [])

  const addShop = async (updatedData: Shop) => {
    try {
      await addDoc(collection(fbsdb, shopDB), {
        name: updatedData.name,
        address: updatedData.address,
        areaId: updatedData.areaId,
        areaCode: updatedData.areaCode,
        // state: updatedData.state,
        organizations: updatedData.organizations,
        organizationIds: updatedData.organizationIds,
        dateCreated: new Date(),
        dateUpdated: new Date(),
      })

      setAlert({
        isAlert: true,
        msg: 'New ECP added!',
        variant: 'success',
      })

      formik.setValues(initialValues)
    } catch (e) {
      console.log(e)
      setAlert({
        isAlert: true,
        msg: 'Failed to add new ECP.',
        variant: 'danger',
      })
    }

    setLoading(false)
  }

  const editShop = async (updatedData: Shop) => {
    try {
      if (eid) {
        await updateDoc(doc(fbsdb, shopDB, eid), {
          name: updatedData.name,
          address: updatedData.address,
          areaId: updatedData.areaId,
          areaCode: updatedData.areaCode,
          // state: updatedData.state,
          organizations: updatedData.organizations,
          organizationIds: updatedData.organizationIds,
          dateUpdated: new Date(),
        })

        setAlert({
          isAlert: true,
          msg: 'ECP updated!',
          variant: 'success',
        })
      }
    } catch (e) {
      console.log(e)
      setAlert({
        isAlert: true,
        msg: 'Update ECP failed.',
        variant: 'danger',
      })
    }

    setLoading(false)
  }

  const loadAreas = async () => {
    const q = await getDocs(query(collection(fbsdb, areaDB)))
    const objList: any[] = q.docs.map((doc: any) => {
      const obj = doc.data() as any
      obj.id = doc.id

      return obj
    })

    objList.sort((a, b) => a.areacode.localeCompare(b.areacode))

    setAreas(objList)
  }

  const loadOrganizations = async () => {
    const q = await getDocs(query(collection(fbsdb, organizationDB)))
    const objList: any[] = q.docs.map((doc: any) => {
      const obj = doc.data() as any
      obj.id = doc.id
      obj.isSelected = false
      if (data.organizations) {
        const inx = data.organizations.findIndex((e) => e.id === obj.id)
        if (inx >= 0) {
          obj.isSelected = true
        }
      }

      return obj
    })
    setOrganizations(objList)
  }

  const orgSelectHandler = async (oid: string, selected: boolean) => {
    const newList = [...organizations]
    const inx = organizations.findIndex((e) => e.id === oid)
    newList[inx].isSelected = selected
    setOrganizations(newList)
  }

  return (
    <>
      {alert.isAlert && (
        <Alert key='danger' variant={alert.variant}>
          {alert.msg}{' '}
          <Link className='fw-bolder' to={'/management/ecp/list'}>
            Back to ECP list
          </Link>
        </Alert>
      )}
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{editMode ? 'Edit ECP / Shop' : 'New ECP / Shop'}</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='ECP Name'
                        {...formik.getFieldProps('name')}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.name}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Address</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='ECP Address'
                        {...formik.getFieldProps('address')}
                      />
                      {formik.touched.address && formik.errors.address && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.address}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Delivery Area
                </label>

                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg'
                    {...formik.getFieldProps('areaId')}
                  >
                    <option value=''>Select delivery area</option>
                    {areas.map((area) => (
                      <option key={area.id} value={area.id}>
                        {area.areacode}
                      </option>
                    ))}
                  </select>
                  {formik.touched.areaId && formik.errors.areaId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.areaId}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Organization / Supplier Group
                </label>

                <div className='col-lg-8 fv-row'>
                  <Form.Group className='mt-3'>
                    {organizations.map((org) => (
                      <Form.Check
                        className='mb-3'
                        onChange={(event: any) => {
                          orgSelectHandler(org.id, event.currentTarget.checked)
                        }}
                        key={org.id}
                        checked={org.isSelected}
                        type='checkbox'
                        label={org.name}
                      />
                    ))}
                  </Form.Group>
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default ECPManagement
