import {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {fbsdb} from '../../utils/firebase'
import {addDoc, collection, doc, onSnapshot, updateDoc} from 'firebase/firestore'
import Organization from '../../models/Organization'
import {Alert} from 'react-bootstrap'
import Department from '../../models/Department'

const departmentSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string(),
  color: Yup.string(),
})
const departmentDB = 'departments'

const DepartmentManagement: React.FC = () => {
  const params = useParams()
  const did = params.id ? params.id.toString() : undefined

  const initialValues: Department = {
    name: '',
    description: '',
    color: '',
  }

  const [data, setData] = useState<Department>(initialValues)
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState({isAlert: false, msg: '', variant: 'primary'})

  const formik = useFormik<Department>({
    initialValues,
    validationSchema: departmentSchema,
    onSubmit: (values) => {
      setLoading(true)
      setAlert({isAlert: false, msg: '', variant: 'primary'})
      // values.dateCreated = new Date()
      const updatedData = Object.assign(data, values)
      setData(updatedData)
      if (editMode) {
        editDepartment(updatedData)
      } else {
        addDepartment(updatedData)
      }
      setLoading(false)
    },
  })

  const updateData = (fieldsToUpdate: Partial<Organization>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  useEffect(() => {
    if (did) {
      setEditMode(true)
      const doDocRef = doc(fbsdb, departmentDB, did)
      onSnapshot(doDocRef, (doc) => {
        const department = doc.data() as any
        department.id = did
        updateData(department)
        // formik.setValues(usr)
        formik.setValues(data)
      })
    }
  }, [])

  const addDepartment = async (updatedData: Department) => {
    try {
      await addDoc(collection(fbsdb, departmentDB), {
        name: updatedData.name,
        description: updatedData.description,
        color: updatedData.color,
      })

      setAlert({
        isAlert: true,
        msg: 'New Department / Group Added!',
        variant: 'success',
      })

      formik.setValues(initialValues)
    } catch (e) {
      console.log(e)
      setAlert({
        isAlert: true,
        msg: 'Failed to add new Department / Group.',
        variant: 'danger',
      })
    }

    setLoading(false)
  }

  const editDepartment = async (updatedData: Department) => {
    try {
      if (did) {
        await updateDoc(doc(fbsdb, departmentDB, did), {
          name: updatedData.name,
          description: updatedData.description,
          color: updatedData.color,
        })
      }

      setAlert({
        isAlert: true,
        msg: 'Department / Group Updated!',
        variant: 'success',
      })
    } catch (e) {
      console.log(e)
      setAlert({
        isAlert: true,
        msg: 'Failed to update Department / Group.',
        variant: 'danger',
      })
    }

    setLoading(false)
  }

  return (
    <>
      {alert.isAlert && (
        <Alert key='danger' variant={alert.variant}>
          {alert.msg}{' '}
          <Link className='fw-bolder' to={'/management/departments/list'}>
            Back to department list
          </Link>
        </Alert>
      )}
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>
              {editMode ? 'Edit Department / Group' : 'New Department / Group'}
            </h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Department / Group Name
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Name'
                        {...formik.getFieldProps('name')}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.name}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Description</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Note'
                    {...formik.getFieldProps('description')}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.description}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Color Tag</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='color #'
                    {...formik.getFieldProps('color')}
                  />
                  {formik.touched.color && formik.errors.color && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.color}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default DepartmentManagement
