import {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'

const RemoveConfirmationModal = (props: any) => {
  const m = props.message

  const [modalShow, setModalShow] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (props.modalShow !== null) {
      setModalShow(props.modalShow)
    }
    setMessage(m)
  }, [props.modalShow, m])

  return (
    <Modal show={modalShow} onHide={props.ModalCloseHandler} backdrop='static' keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure to delete {message}? Action cannot be reverted.</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={props.ModalCloseHandler}>
          Cancel
        </Button>
        <Button variant='danger' onClick={props.ModalConfirmHandler}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default RemoveConfirmationModal
