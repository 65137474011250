import {useEffect, useState} from 'react'
import {Button, Form, Spinner} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {fbsdb} from '../../utils/firebase'
import {collection, deleteDoc, doc, getDocs, query, updateDoc} from 'firebase/firestore'
import State from '../../models/State'
import {useAuth} from '../auth'

const stateDB = 'states'

const StateList: React.FC = () => {
  const init: any[] = []
  const [states, setStates] = useState(init)
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser} = useAuth()

  useEffect(() => {
    loadStates()
  }, [])

  const setDisplay = async (stateId: string, display: boolean) => {
    const newList = [...states]
    const inx = states.findIndex((e) => e.id === stateId)
    newList[inx].display = display
    setStates(newList)
    updateDoc(doc(fbsdb, stateDB, stateId), {
      display: display,
    })
  }

  const loadStates = async () => {
    setIsLoading(true)
    const q = await getDocs(query(collection(fbsdb, stateDB)))
    const objList: any[] = q.docs.map((doc: any) => {
      const obj = doc.data() as any
      obj.id = doc.id

      return obj
    })

    objList.sort((a, b) => a.name.localeCompare(b.name))

    setStates(objList)
    setIsLoading(false)
  }

  return (
    <>
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              State List {isLoading && <Spinner animation='border' variant='warning' size='sm' />}
            </span>
            <span className='text-muted mt-1 fw-semibold fs-7'></span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Display / Hide</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {states.map((state) => (
                  <tr key={state.id}>
                    <td className='text-dark fw-bold fs-6'>{state.name}</td>
                    <td>
                      <div className='text-dark fw-bold d-block fs-6'>{state.code}</div>
                    </td>
                    <td>
                      <Form.Check // prettier-ignore
                        type='switch'
                        id='custom-switch'
                        checked={state.display}
                        onChange={(value) => {
                          setDisplay(state.id, value.currentTarget.checked)
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default StateList
