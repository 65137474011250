import classNames from 'classnames'
import {collection, getDocs, orderBy, query, where} from 'firebase/firestore'
import {useRef, useState} from 'react'
import {Alert, Button, Form, InputGroup, Spinner} from 'react-bootstrap'
import Moment from 'react-moment'
import Activity from '../models/Activity'
import Shipment from '../models/Shipment'
import {fbsdb} from '../utils/firebase'
import ModalImage from "react-modal-image";

const shipmentDB = 'shipments'
const shipmentActivityDB = 'activities'

const TrackShipmentPage: React.FC = () => {
  // const [key, setKey] = useState('')
  const keyRef = useRef('')
  const [shipment, setShipment] = useState<Shipment | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const init: Activity[] = []
  const [activities, setActivities] = useState(init)
  const [error, setError] = useState({isError: false, msg: ''})

  const keyHandler = (event: any) => {
    keyRef.current = event.target.value.toUpperCase()
  }

  const onSearchHandler = async () => {
    setIsLoading(true)
    setError({isError: false, msg: ''})
    const q = await getDocs(
      query(
        collection(fbsdb, shipmentDB),
        where('code', '==', keyRef.current),
        orderBy('dateCreated', 'desc')
      )
    )
    if (q.docs.length > 0) {
      // get the latest
      const obj = q.docs[0].data() as any
      obj.id = q.docs[0].id
      setShipment(obj)
      loadActivities(obj.id)
    } else {
      setError({isError: true, msg: 'Sorry! No record found.'})
      setIsLoading(false)
    }
  }

  const onKeyPressHandler = async (event: any) => {
    if (event.key === 'Enter') {
      keyRef.current = event.target.value.toUpperCase()
      onSearchHandler()
    }
  }

  const loadActivities = async (shipmentId: string) => {
    const q = await getDocs(
      query(
        collection(fbsdb, shipmentDB + '/' + shipmentId + '/' + shipmentActivityDB),
        orderBy('dateCreated', 'desc')
      )
    )
    const objList: any[] = q.docs.map((doc: any) => {
      const obj = doc.data() as any
      obj.id = doc.id

      return obj
    })

    setActivities(objList)
    setIsLoading(false)
  }

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <div className='d-flex flex-column flex-center text-center p-10'>
          <div className='card card-flush  w-lg-650px py-5'>
            <div className='card-body py-10 py-lg-10'>
              <h4 className='mb-5'>Enter Your Parcel tracking Code / Barcode</h4>
              <InputGroup className='mb-3'>
                <Form.Control
                  placeholder='Input here'
                  aria-label='Tracking code'
                  aria-describedby='track-shipment'
                  onChange={keyHandler}
                  onKeyUp={onKeyPressHandler}
                />
                <Button
                  disabled={isLoading}
                  onClick={onSearchHandler}
                  variant='danger'
                  id='button-addon2'
                >
                  Search
                </Button>
              </InputGroup>
              {/* <span>*case sensitive</span> */}
            </div>
          </div>
        </div>
        {error.isError && (
          <Alert key='warning' variant='warning' className='w-lg-650px text-center'>
            {error.msg}
          </Alert>
        )}
        <div className='d-flex flex-column flex-center p-10'>
          {isLoading && (
            <Spinner animation='border' variant='primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          )}
          {!isLoading && shipment && !error.isError && (
            <div className={`card mb-5 shadow-none w-lg-650px`}>
              {/* begin::Header */}
              <div className='card-header align-items-center border-0 mt-4'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='fw-bold mb-2 text-dark'>{shipment.code}</span>
                  <span className='text-muted fw-semibold fs-7'>Tracking Number</span>
                </h3>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className='card-body pt-5'>
                {activities.length === 0 && (
                  <Alert key='info' variant='info' className='text-center'>
                    Empty Activity.
                  </Alert>
                )}
                {/* begin::Timeline */}
                <div className='timeline-label'>
                  {activities.map((activity) => (
                    <div key={activity.id} className='timeline-item'>
                      {/* begin::Label */}
                      <div className='timeline-label fw-bold text-gray-800 fs-8'>
                        <Moment format='DD MMM'>{activity.dateCreated.toDate()}</Moment>
                        <br />
                        <Moment format='HH:mm'>{activity.dateCreated.toDate()}</Moment>
                      </div>
                      {/* end::Label */}
                      {/* begin::Badge */}
                      <div className='timeline-badge'>
                        <i
                          className={classNames('fa', 'fa-genderless', 'fs-1', {
                            'text-warning': activity.action === 'checked_in',
                            'text-primary': activity.action === 'out_for_delivery',
                            'text-success': activity.action === 'completed',
                            'text-secondary':
                              activity.action === 'achieved' || activity.action === 'returned',
                            'text-danger': activity.action === 'problem',
                          })}
                        ></i>
                      </div>
                      {/* end::Badge */}
                      {/* begin::Text */}
                      <div className='timeline-content d-flex'>
                        {activity.action === 'checked_in' && (
                          <span className='fw-bold text-gray-800 ps-3'>
                            Parcel has arrived at the delivery hub: {activity.to}
                          </span>
                        )}
                        {activity.action === 'out_for_delivery' && (
                          <span className='fw-bold text-gray-800 ps-3'>
                            Parcel is out for delivery
                          </span>
                        )}
                        {activity.action === 'achieved' && (
                          <span className='fw-bold text-gray-800 ps-3'>
                            Shipment have been marked as achieved
                          </span>
                        )}
                        {activity.action === 'returned' && (
                          <span className='fw-bold text-gray-800 ps-3'>
                            Parcel have been returned to supplier
                          </span>
                        )}
                        {activity.action === 'completed' && (
                          <span className='fw-bold text-gray-800 ps-3'>
                            Parcel has been delivered
                            <br />
                            {shipment?.podImageURL && (
                              <ModalImage
                                small={shipment.podImageURL}
                                large={shipment.podImageURL}
                                alt="Proof of delivery"
                              />
                            )}  
                          </span>
                        )}
                        {activity.action === 'problem' && (
                          <span className='fw-bold text-gray-800 ps-3'>
                            Parcel has been marked as problem shipment.
                            <br />
                            <div>Reason: {activity.optional.problemdesc}</div>
                          </span>
                        )}
                      </div>
                      {/* end::Text */}
                    </div>
                  ))}
                </div>
                {/* end::Timeline */}
              </div>
              {/* end: Card Body */}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TrackShipmentPage
