import {useEffect, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {fbsdb} from '../../utils/firebase'
import {collection, deleteDoc, doc, getDocs, query} from 'firebase/firestore'
import Station from '../../models/Station'
import RemoveConfirmationModal from '../../widgets/RemoveConfirmationModal'
import {useAuth} from '../auth'

const stationDB = 'stations'

const StationList: React.FC = () => {
  const navigate = useNavigate()
  const init: any[] = []
  const [stations, setStations] = useState(init)
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser} = useAuth()

  useEffect(() => {
    loadStations()
  }, [])

  const loadStations = async () => {
    setIsLoading(true)
    const q = await getDocs(query(collection(fbsdb, stationDB)))
    const objList: any[] = q.docs.map((doc: any) => {
      const obj = doc.data() as any
      obj.id = doc.id

      return obj
    })

    objList.sort((a, b) => a.name.localeCompare(b.name))

    setStations(objList)
    setIsLoading(false)
  }

  const [selectedStation, setSelectedStation] = useState<Station | undefined>(undefined)
  const [modalDeleteShow, setModalDeleteShow] = useState(false)
  const [deleteMessage, setDeleteMessage] = useState('')
  const ModalDeleteCloseHandler = () => setModalDeleteShow(false)
  const ModalDeleteShowHandler = (sid: string | undefined) => {
    if (sid) {
      const sta = stations.find((e) => e.id === sid)
      setSelectedStation(sta)
      setDeleteMessage(sta?.name ? sta.name : '')
      setModalDeleteShow(true)
    }
  }
  const ModalDeleteConfirmHandler = async () => {
    if (selectedStation) {
      const groupDocRef = doc(fbsdb, stationDB + '/' + selectedStation.id)
      await deleteDoc(groupDocRef)
    }

    setModalDeleteShow(false)
    loadStations()
  }

  return (
    <>
      <RemoveConfirmationModal
        message={deleteMessage}
        modalShow={modalDeleteShow}
        ModalConfirmHandler={ModalDeleteConfirmHandler}
        ModalCloseHandler={ModalDeleteCloseHandler}
      />
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Station List {isLoading && <Spinner animation='border' variant='warning' size='sm' />}
            </span>
            <span className='text-muted mt-1 fw-semibold fs-7'></span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <Button
              onClick={() => navigate('/management/stations/new')}
              className='btn btn-sm btn-light-primary'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              New Station
            </Button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Name</th>
                  <th className='min-w-140px'>Port Code</th>
                  <th className='min-w-120px'>Organization / Supplier</th>
                  <th className='min-w-100px text-end'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {stations.map((station) => (
                  <tr key={station.id}>
                    <td>
                      <Link
                        to={'/management/stations/' + station.id + '/edit'}
                        className='text-dark fw-bold text-hover-primary d-block fs-6'
                      >
                        {station.name}
                      </Link>
                    </td>
                    <td>
                      <div className='text-dark fw-bold d-block fs-6'>{station.portcode}</div>
                    </td>
                    <td>
                      <div className='text-dark fw-bold d-block fs-6'>
                        {station.organizationName}
                      </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <Link
                          to={'/management/stations/' + station.id + '/edit'}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </Link>
                        {currentUser && (
                          <>
                            {(currentUser.role === 'admin' ||
                              currentUser.role === 'staff' ||
                              currentUser.role === 'manager') && (
                              <Button
                                variant='outline-danger'
                                onClick={() => {
                                  ModalDeleteShowHandler(station.id)
                                }}
                                size='sm'
                                className='btn-icon me-1'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen040.svg'
                                  className='svg-icon-3'
                                />
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default StationList
