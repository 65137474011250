import {addDoc, collection, doc, getDocs, query, updateDoc} from 'firebase/firestore'
import {useEffect, useState} from 'react'
import {Alert, Button, Col, Form, Modal, Row} from 'react-bootstrap'
import {fbsdb} from '../../utils/firebase'
import OrganizationGroup from '../../models/OrganizationGroup'

const organizationDB = 'organizations'
const organizationBillingDB = 'organization_billing_group'
const groupDB = 'groups'

const SupplierGroupModal = (props: any) => {
  const billingGroup = props.billingGroup
  const sid = props.sid
  const sname = props.sname

  const [name, setName] = useState('')
  const [id, setId] = useState('')
  const [supplierId, setSupplierId] = useState('')
  const [modalShow, setModalShow] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const init: any[] = []
  const [groups, setGroups] = useState<OrganizationGroup[]>(init)
  const [alert, setAlert] = useState({isAlert: false, msg: '', variant: 'primary'})

  useEffect(() => {
    setAlert({isAlert: false, msg: '', variant: 'primary'})
    setSupplierId(sid)

    if (billingGroup) {
      setName(billingGroup.name)
      setId(billingGroup.id)
      setEditMode(true)
    } else {
      setName('')
      setId('')
      setEditMode(false)
    }
    loadGroups()
  }, [billingGroup, sid])

  useEffect(() => {
    if (props.modalShow !== null) {
      setModalShow(props.modalShow)
    }
  }, [props.modalShow])

  const loadGroups = async () => {
    const q = await getDocs(query(collection(fbsdb, organizationDB + '/' + sid + '/' + groupDB)))
    const objList: any[] = q.docs.map((doc: any) => {
      const obj = doc.data() as any
      obj.id = doc.id
      obj.isSelected = false

      if (billingGroup) {
        const inx = billingGroup.groupIds.findIndex((eg: any) => eg === obj.id)
        if (inx >= 0) {
          obj.isSelected = true
        }
      }

      return obj
    })

    objList.sort((a, b) => a.name.localeCompare(b.name))

    setGroups(objList)
  }

  const nameHandler = (event: any) => {
    setName(event.currentTarget.value)
  }

  const modalSaveHandler = async () => {
    const bgs: any[] = []
    const bgis: any[] = []
    const selectedGroups = groups.filter((e) => e.isSelected === true)
    selectedGroups.forEach((e) => {
      bgs.push({id: e.id, name: e.name})
      bgis.push(e.id)
    })

    if (name.trim() === '') {
      setAlert({isAlert: true, msg: 'Name cannot be empty', variant: 'warning'})
      return
    }

    if (bgis.length === 0) {
      setAlert({isAlert: true, msg: 'No Sub Supplier selected', variant: 'warning'})
      return
    }

    if (editMode) {
      const obj = {
        order: 2,
        name: name,
        groupIds: bgis,
        groups: bgs,
      }
      const groupDocRef = doc(fbsdb, organizationBillingDB, id)
      await updateDoc(groupDocRef, obj)
    } else {
      const obj = {
        default: false,
        order: 2,
        name: name,
        organizationId: sid,
        organizationName: sname,
        groupIds: bgis,
        groups: bgs,
      }
      await addDoc(collection(fbsdb, organizationBillingDB), obj)
    }
    setAlert({isAlert: true, msg: 'Successfully Saved', variant: 'success'})
    props.ModalSaveHandler()
  }

  const gSelectHandler = async (gid: string, selected: boolean) => {
    // const newList = [...groups]
    const inx = groups.findIndex((e) => e.id === gid)
    groups[inx].isSelected = selected

    setGroups([...groups])
  }

  return (
    <Modal show={modalShow} onHide={props.ModalCloseHandler} backdrop='static' keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>
          {editMode && 'Edit Billing Group'}
          {!editMode && 'New Billing Group'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alert.isAlert && <Alert variant={alert.variant}>{alert.msg}</Alert>}
        <Form>
          <Form.Group as={Row} className='mb-3' controlId='formName'>
            <Form.Label column sm={3}>
              Name
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type='text'
                placeholder='Billing Group Name'
                defaultValue={name}
                onChange={nameHandler}
              />
            </Col>
          </Form.Group>

          <Form.Group className='mt-6'>
            <Form.Label column>Sub Suppliers under this billing group</Form.Label>
            <Row className='mt-3'>
              {groups.map((g: any) => (
                <Col sm={4} key={g.id}>
                  <Form.Check
                    className='mb-3'
                    onChange={(event: any) => {
                      gSelectHandler(g.id, event.currentTarget.checked)
                    }}
                    checked={g.isSelected}
                    type='checkbox'
                    label={g.name}
                  />
                </Col>
              ))}
            </Row>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={props.ModalCloseHandler}>
          Close
        </Button>
        <Button variant='warning' onClick={modalSaveHandler}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SupplierGroupModal
