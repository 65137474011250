import {doc, getDoc} from 'firebase/firestore'
import {useEffect, useState} from 'react'
import {Col, ProgressBar, Row} from 'react-bootstrap'
import User from '../models/User'
import {useAuth} from '../modules/auth'
import {fbsdb} from '../utils/firebase'

const userDB = 'users'

const MyProfilePage: React.FC = () => {
  const {currentUser} = useAuth()
  const [user, setUser] = useState<User | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    loadMe()
  }, [])

  const loadMe = async () => {
    setIsLoading(true)
    const userDoc = (
      await getDoc(doc(fbsdb, userDB, currentUser?.id ? currentUser?.id : ''))
    ).data()
    if (userDoc) {
      setUser(userDoc as User)
    }
    setIsLoading(false)
  }

  return (
    <>
      {isLoading && <ProgressBar variant='primary' animated now={85} />}
      {user && (
        <div className={`card mb-5 mb-xl-8`}>
          <div className='card-body'>
            <div className='py-10'>
              <Row>
                <Col md='8' className='pt-2 pb-2 fs-5'>
                  <Row>
                    <Col xs='4'>Name</Col>
                    <Col md='8' className='fw-bold'>
                      {user?.name}
                    </Col>
                  </Row>
                </Col>
                <Col md='8' className='pt-2 pb-2 fs-5'>
                  <Row>
                    <Col xs='4'>Username</Col>
                    <Col md='8' className='fw-bold'>
                      {user?.username}
                    </Col>
                  </Row>
                </Col>
                <Col md='8' className='pt-2 pb-2 fs-5'>
                  <Row>
                    <Col xs='4'>Email</Col>
                    <Col md='8' className='fw-bold'>
                      {user?.email}
                    </Col>
                  </Row>
                </Col>
                <Col md='8' className='pt-2 pb-2 fs-5'>
                  <Row>
                    <Col xs='4'>Role</Col>
                    <Col md='8' className='fw-bold'>
                      {user?.role}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default MyProfilePage
