import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import Shipment from '../models/Shipment'
import {collection, doc, onSnapshot, orderBy, query} from 'firebase/firestore'
import {fbsdb} from '../utils/firebase'
import Activity from '../models/Activity'
import {Alert, Col, Row} from 'react-bootstrap'
import Moment from 'react-moment'
import classNames from 'classnames'
import {useAuth} from '../modules/auth'
import ModalImage from "react-modal-image";

const shipmentDB = 'shipments'
const shipmentActivityDB = 'activities'

const ShipmentInfoPage: React.FC = () => {
  const params = useParams()
  const {currentUser} = useAuth()
  const sid = params.id ? params.id.toString() : undefined
  const [shipment, setShipment] = useState<Shipment | undefined>(undefined)
  const init: Activity[] = []
  const [activities, setActivities] = useState(init)
  const [error, setError] = useState({isError: false, msg: ''})
  const [authorized, setAuthorized] = useState(true)

  useEffect(() => {
    if (sid) {
      loadShipment()
      loadActivities()
    }
  }, [sid])

  const loadShipment = () => {
    if (sid) {
      const doDocRef = doc(fbsdb, shipmentDB, sid)
      onSnapshot(doDocRef, (doc) => {
        if (doc.exists()) {
          setError({isError: false, msg: ''})
          const shipment = doc.data() as Shipment
          shipment.id = doc.id

          // check if authorize to view
          if (
            currentUser?.role === 'customer' &&
            currentUser?.organizationId !== shipment.fromOrganizationId
          ) {
            setAuthorized(false)
          }

          setShipment(shipment)
        } else {
          setError({isError: true, msg: 'Shipment not found'})
        }
      })
    }
  }
  const loadActivities = () => {
    const q = query(
      collection(fbsdb, shipmentDB + '/' + sid + '/' + shipmentActivityDB),
      orderBy('dateCreated', 'desc')
    )

    onSnapshot(q, (querySnapshot) => {
      const list: Activity[] = []
      querySnapshot.forEach((doc) => {
        const obj = doc.data() as any
        obj.id = doc.id
        list.push(obj)
      })
      setActivities(list)
    })
  }
  const loadProblem = () => {}

  return (
    <>
      {error.isError && (
        <Alert key='danger' variant='danger'>
          {error.msg}
        </Alert>
      )}

      <div>
        {shipment && authorized && (
          <div className={`card mb-5 mb-xl-8`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-1 mb-1'>{shipment.code}</span>
                <span className='text-muted mt-1 fw-semibold fs-7'></span>
              </h3>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
              <div className='pb-10'>
                <Row>
                  <Col md='6' className='pt-2 pb-2'>
                    <Row>
                      <Col xs='4'>Date Created</Col>
                      <Col md='8' className='fw-bold'>
                        <Moment format='DD/MM/YYYY HH:mm:ss'>
                          {shipment.dateCreated.toDate()}
                        </Moment>
                      </Col>
                    </Row>
                  </Col>
                  <Col md='6' className='pt-2 pb-2'>
                    <Row>
                      <Col xs='4'>Date Updated</Col>
                      <Col md='8' className='fw-bold'>
                        <Moment format='DD/MM/YYYY HH:mm:ss'>
                          {shipment.dateUpdated.toDate()}
                        </Moment>
                      </Col>
                    </Row>
                  </Col>
                  <Col md='6' className='pt-2 pb-2'>
                    <Row>
                      <Col xs='4'>Status</Col>
                      <Col md='8' className='fw-bold'>
                        {shipment.status === 'completed' && (
                          <span className='fs-7 fw-bold text-success'>Delivered</span>
                        )}
                        {shipment.status === 'problem' && (
                          <span className='fs-7 fw-bold text-danger'>Problem Shipment</span>
                        )}
                        {shipment.status === 'checked_in' && (
                          <span className='fs-7 fw-bold text-warning'>Checked In</span>
                        )}
                        {shipment.status === 'out_for_delivery' && (
                          <span className='fs-7 fw-bold text-primary'>Out For Delivery</span>
                        )}
                        {shipment.status === 'returned' && (
                          <span className='fs-7 fw-bold text-muted'>Returned</span>
                        )}
                        {shipment.status === 'achieved' && (
                          <span className='fs-7 fw-bold text-muted'>Achieved</span>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              {shipment.checkinDateTime && (
                <div className='pb-10'>
                  <h3 className='card-title align-items-start flex-column pt-5 pb-5'>
                    <span className='card-label fw-bold fs-3 text-muted mb-1'>Check In AWB</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'></span>
                  </h3>
                  <Row>
                    <Col md='6' className='pt-2 pb-2'>
                      <Row>
                        <Col xs='4'>From Station</Col>
                        <Col md='8' className='fw-bold'>
                          {shipment.fromStationName}
                        </Col>
                      </Row>
                    </Col>
                    <Col md='6' className='pt-2 pb-2'>
                      <Row>
                        <Col xs='4'>Supplier Group</Col>
                        <Col md='8' className='fw-bold'>
                          {shipment.fromOrganizationGroupName} ({shipment.fromOrganizationName})
                        </Col>
                      </Row>
                    </Col>
                    <Col md='6' className='pt-2 pb-2'>
                      <Row>
                        <Col xs='4'>Checked In Date</Col>
                        <Col md='8' className='fw-bold'>
                          <Moment format='DD/MM/YYYY HH:mm:ss'>
                            {shipment.checkinDateTime.toDate()}
                          </Moment>
                        </Col>
                      </Row>
                    </Col>
                    <Col md='6' className='pt-2 pb-2'>
                      <Row>
                        <Col xs='4'>Trip</Col>
                        <Col md='8' className='fw-bold'>
                          {shipment.checkinTrip === 1 && '1st Trip'}{' '}
                          {shipment.checkinTrip === 2 && '2nd Trip'}
                        </Col>
                      </Row>
                    </Col>
                    <Col md='6' className='pt-2 pb-2'>
                      <Row>
                        <Col xs='4'>Checked In By</Col>
                        <Col md='8' className='fw-bold'>
                          {shipment.checkinByName}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              )}

              {shipment.runsheetDateCreated && (
                <div className='pb-10'>
                  <h3 className='card-title align-items-start flex-column pt-5 pb-5'>
                    <span className='card-label fw-bold fs-3 text-muted mb-1'>
                      Delivery Runsheet
                    </span>
                    <span className='text-muted mt-1 fw-semibold fs-7'></span>
                  </h3>
                  <Row>
                    <Col md='6' className='pt-2 pb-2'>
                      <Row>
                        <Col xs='4'>Delivery Trip</Col>
                        <Col md='8' className='fw-bold'>
                          {shipment.runsheetTrip === 1 && '1st Trip'}{' '}
                          {shipment.runsheetTrip === 2 && '2nd Trip'}
                        </Col>
                      </Row>
                    </Col>
                    <Col md='6' className='pt-2 pb-2'>
                      <Row>
                        <Col xs='4'>Delivery Date</Col>
                        <Col md='8' className='fw-bold'>
                          {shipment.runsheetDate}
                        </Col>
                      </Row>
                    </Col>
                    <Col md='6' className='pt-2 pb-2'>
                      <Row>
                        <Col xs='4'>Date Created</Col>
                        <Col md='8' className='fw-bold'>
                          <Moment format='DD/MM/YYYY HH:mm:ss'>
                            {shipment.runsheetDateCreated.toDate()}
                          </Moment>
                        </Col>
                      </Row>
                    </Col>
                    <Col md='6' className='pt-2 pb-2'>
                      <Row>
                        <Col xs='4'>Assigned By</Col>
                        <Col md='8' className='fw-bold'>
                          {shipment.assignedByName}
                        </Col>
                      </Row>
                    </Col>
                    <Col md='6' className='pt-2 pb-2'>
                      <Row>
                        <Col xs='4'>Driver</Col>
                        <Col md='8' className='fw-bold'>
                          {shipment.driverName} ({shipment.driverCode})
                        </Col>
                      </Row>
                    </Col>
                    <Col md='6' className='pt-2 pb-2'>
                      <Row>
                        <Col xs='4'>Area Code</Col>
                        <Col md='8' className='fw-bold'>
                          {shipment.areaCode}
                        </Col>
                      </Row>
                    </Col>
                    <Col md='6' className='pt-2 pb-2'>
                      <Row>
                        <Col xs='4'>Destination</Col>
                        <Col md='8' className='fw-bold'>
                          {shipment.deliveryToName}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              )}
              {shipment.podDate && (
                <div className='pb-10'>
                  <h3 className='card-title align-items-start flex-column pt-5 pb-5'>
                    <span className='card-label fw-bold fs-3 text-muted mb-1'>
                      Proof of Delivery
                    </span>
                    <span className='text-muted mt-1 fw-semibold fs-7'></span>
                  </h3>
                  <Row>
                    <Col md='6' className='pt-2 pb-2'>
                      <Row>
                        <Col xs='4'>POD Status</Col>
                        <Col md='8' className='fw-bold'>
                          {shipment.podStatus}
                        </Col>
                      </Row>
                    </Col>
                    <Col md='6' className='pt-2 pb-2'>
                      <Row>
                        <Col xs='4'>POD Date</Col>
                        <Col md='8' className='fw-bold'>
                          <Moment format='DD/MM/YYYY HH:mm:ss'>{shipment.podDate.toDate()}</Moment>
                        </Col>
                      </Row>
                    </Col>
                    <Col md='6' className='pt-2 pb-2'>
                      <Row>
                        <Col xs='4'>POD By</Col>
                        <Col md='8' className='fw-bold'>
                          {shipment.podByName}
                        </Col>
                      </Row>
                    </Col>
                    <Col md='6' className='pt-2 pb-2'>
                      <Row>
                        <Col xs='4'>Indentifier</Col>
                        <Col md='8' className='fw-bold'>
                          {shipment.identity}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              )}

              {shipment.problemDate && (
                <div className='pb-10'>
                  <h3 className='card-title align-items-start flex-column pt-5 pb-5'>
                    <span className='card-label fw-bold fs-3 text-muted mb-1'>Current Problem</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'></span>
                  </h3>
                  <Row>
                    <Col md='6'>
                      <Row>
                        <Col xs='4'>Code</Col>
                        <Col md='8' className='fw-bold'>
                          {shipment.problemcode}
                        </Col>
                      </Row>
                    </Col>
                    <Col md='6'>
                      <Row>
                        <Col xs='4'>Description</Col>
                        <Col md='8' className='fw-bold'>
                          {shipment.problemdesc}
                        </Col>
                      </Row>
                    </Col>
                    <Col md='6'>
                      <Row>
                        <Col xs='4'>Marked Date</Col>
                        <Col md='8' className='fw-bold'>
                          <Moment format='DD/MM/YYYY HH:mm:ss'>
                            {shipment.problemDate.toDate()}
                          </Moment>
                        </Col>
                      </Row>
                    </Col>
                    <Col md='6'>
                      <Row>
                        <Col xs='4'>Marked By</Col>
                        <Col md='8' className='fw-bold'>
                          {shipment.problemByName}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
            {/* end::Table container */}
          </div>
        )}
        <div className={`card mb-5 mb-xl-8`}>
          {/* begin::Header */}
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>
                Activities {shipment && <span>- {shipment.code}</span>}
              </span>
              <span className='text-muted mt-1 fw-semibold fs-7'></span>
            </h3>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body pt-5'>
            {activities.length === 0 && (
              <Alert key='info' variant='info' className='text-center'>
                Empty Activity.
              </Alert>
            )}
            {/* begin::Timeline */}
            <div className='timeline-label'>
              {activities.map((activity) => (
                <div key={activity.id} className='timeline-item'>
                  {/* begin::Label */}
                  <div className='timeline-label fw-bold text-gray-800 fs-8'>
                    <Moment format='DD MMM'>{activity.dateCreated.toDate()}</Moment>
                    <br />
                    <Moment format='HH:mm'>{activity.dateCreated.toDate()}</Moment>
                  </div>
                  {/* end::Label */}
                  {/* begin::Badge */}
                  <div className='timeline-badge'>
                    <i
                      className={classNames('fa', 'fa-genderless', 'fs-1', {
                        'text-warning': activity.action === 'checked_in',
                        'text-primary': activity.action === 'out_for_delivery',
                        'text-success': activity.action === 'completed',
                        'text-secondary':
                          activity.action === 'achieved' || activity.action === 'returned',
                        'text-danger': activity.action === 'problem',
                      })}
                    ></i>
                  </div>
                  {/* end::Badge */}
                  {/* begin::Text */}
                  <div className='timeline-content d-flex'>
                    {activity.action === 'checked_in' && (
                      <span className='fw-bold text-gray-800 ps-3'>
                        Parcel has arrived at the delivery hub: {activity.to}
                      </span>
                    )}
                    {activity.action === 'out_for_delivery' && (
                      <span className='fw-bold text-gray-800 ps-3'>Parcel is out for delivery</span>
                    )}
                    {activity.action === 'achieved' && (
                      <span className='fw-bold text-gray-800 ps-3'>
                        Shipment have been marked as achieved
                      </span>
                    )}
                    {activity.action === 'returned' && (
                      <span className='fw-bold text-gray-800 ps-3'>
                        Parcel have been returned to supplier
                      </span>
                    )}
                    {activity.action === 'completed' && (
                      <span className='fw-bold text-gray-800 ps-3'>
                        Parcel has been delivered
                        <br />
                        {shipment?.podImageURL && (
                          <ModalImage
                            small={shipment.podImageURL}
                            large={shipment.podImageURL}
                            alt="Proof of delivery"
                          />
                        )} 
                      </span>
                    )}
                    {activity.action === 'problem' && (
                      <span className='fw-bold text-gray-800 ps-3'>
                        Parcel has been marked as problem shipment.
                        <br />
                        <div>Reason: {activity.optional.problemdesc}</div>
                      </span>
                    )}
                  </div>
                  {/* end::Text */}
                </div>
              ))}
            </div>
            {/* end::Timeline */}
          </div>
          {/* end: Card Body */}
          {/* end::Table container */}
        </div>
      </div>
    </>
  )
}

export default ShipmentInfoPage
