import React from 'react'
import classnames from 'classnames'
import {usePagination, DOTS} from './usePagination'
import {Pagination} from 'react-bootstrap'
const CustomPagination = (props: any) => {
  const {onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className} = props

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  if (currentPage === 0 || paginationRange!.length < 2) {
    return null
  }

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  let lastPage = paginationRange![paginationRange!.length - 1]

  return (
    <div className='d-flex flex-stack flex-wrap pt-10'>
      <div className='fs-6 fw-bold text-gray-700'>
        Showing {currentPage * pageSize - pageSize + 1} to{' '}
        {currentPage * pageSize > totalCount ? totalCount : currentPage * pageSize} of {totalCount}{' '}
        entries
      </div>

      <Pagination>
        <Pagination.Prev disabled={currentPage === 1} onClick={onPrevious} />
        {/* <Pagination.Ellipsis /> */}
        {paginationRange!.map((pageNumber) => (
          <Pagination.Item
            key={pageNumber}
            onClick={() => onPageChange(pageNumber)}
            active={pageNumber === currentPage}
          >
            {pageNumber}
          </Pagination.Item>
        ))}
        {/* <Pagination.Ellipsis /> */}
        <Pagination.Next disabled={currentPage === lastPage} onClick={onNext} />
      </Pagination>
    </div>
  )
}

export default CustomPagination
