import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useAuth} from '../../../../../app/modules/auth'

export function MenuInner() {
  const intl = useIntl()
  const {currentUser} = useAuth()

  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />

      {currentUser && (
        <>
          {(currentUser.role === 'admin' ||
            currentUser.role === 'staff' ||
            currentUser.role === 'manager') && (
            <>
              <MenuItem title='Shipments' to='/shipments/list' />
              {(currentUser.role === 'admin' || currentUser.role === 'manager') && (
                <MenuInnerWithSub
                  title='Billing Report'
                  to='/reports/billing/supplier'
                  menuPlacement='bottom-start'
                  menuTrigger='click'
                >
                  {/* PAGES */}
                  {/* <MenuItem
                  icon='/media/icons/duotune/general/gen003.svg'
                  to='/reports/billing/overview-monthly'
                  title='Billing Overview - Monthly'
                />
                <MenuItem
                  icon='/media/icons/duotune/general/gen003.svg'
                  to='/reports/billing/overview-daily'
                  title='Billing Overview - Daily'
                />
                <MenuItem
                  icon='/media/icons/duotune/ecommerce/ecm004.svg'
                  to='/reports/billing/overview-outlet'
                  title='Billing Overview - Outlet'
                /> */}
                  <MenuItem
                    icon='/media/icons/duotune/general/gen003.svg'
                    to='/reports/billing/supplier'
                    title='Daily By Supplier'
                  />
                  <MenuItem
                    icon='/media/icons/duotune/ecommerce/ecm004.svg'
                    to='/reports/billing/outlet'
                    title='Daily By Outlet'
                  />
                  <MenuItem
                    icon='/media/icons/duotune/ecommerce/ecm006.svg'
                    to='/reports/billing/driver'
                    title='Driver Commissions'
                  />
                </MenuInnerWithSub>
              )}

              <MenuInnerWithSub
                title='Orders Report'
                to='/reports/droppoint/outlet'
                menuPlacement='bottom-start'
                menuTrigger='click'
              >
                {/* PAGES */}
                <MenuItem
                  icon='/media/icons/duotune/arrows/arr091.svg'
                  to='/reports/droppoint/outlet'
                  title="Outlet's Drop Points"
                />
                <MenuItem
                  icon='/media/icons/duotune/graphs/gra001.svg'
                  to='/reports/orders'
                  title='Orders By Date'
                />
              </MenuInnerWithSub>

              <MenuInnerWithSub
                title='Performance Report'
                to='/reports/KPI-daily'
                menuPlacement='bottom-start'
                menuTrigger='click'
              >
                {/* PAGES */}
                <MenuItem
                  icon='/media/icons/duotune/general/gen014.svg'
                  to='/reports/kpi-daily'
                  title='KPI Daily'
                />
              </MenuInnerWithSub>
            </>
          )}
          {currentUser.role === 'customer' && (
            <>
              {/* PAGES */}
              <MenuItem to='/customers/request-pickup' title='Request Pickup' />
              <MenuItem to='/customers/pickup-list' title='Pickup List' />
              <MenuItem to='/customers/billing-report' title='Billing Report' />
              <MenuItem to='/customers/kpi-report' title='KPI Report' />
              <MenuItem to='/customers/orders-report' title='Orders Report' />
              <MenuItem to='/customers/droppoints-report' title='Droppoints Report' />
              {/* <MenuItem to='/customers/shipments' title='Shipment List' />
              <MenuItem to='/customers/track-shipment' title='Track Shipment' /> */}
            </>
          )}
        </>
      )}
    </>
  )
}
