import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import ShipmentInfoPage from '../pages/ShipmentInfo'
import MyProfilePage from '../pages/MyProfile'
import ManagementPage from '../modules/management/ManagementPage'
import ShipmentsPage from '../modules/shipments/ShipmentsPage'

const PrivateRoutes = () => {
  const UsersPage = lazy(() => import('../modules/user-management/UsersPage'))
  const TransactionsPage = lazy(() => import('../modules/transactions/TransactionsPage'))
  const ReportsPage = lazy(() => import('../modules/reports/ReportsPage'))
  const DriversPage = lazy(() => import('../modules/drivers/DriversPage'))
  const CustomersPage = lazy(() => import('../modules/customers/CustomersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />

        {/* Lazy Modules */}
        <Route
          path='users/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='management/*'
          element={
            <SuspensedView>
              <ManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='transactions/*'
          element={
            <SuspensedView>
              <TransactionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='reports/*'
          element={
            <SuspensedView>
              <ReportsPage />
            </SuspensedView>
          }
        />
        <Route
          path='shipments/*'
          element={
            <SuspensedView>
              <ShipmentsPage />
            </SuspensedView>
          }
        />
        <Route
          path='drivers/*'
          element={
            <SuspensedView>
              <DriversPage />
            </SuspensedView>
          }
        />
        <Route
          path='customers/*'
          element={
            <SuspensedView>
              <CustomersPage />
            </SuspensedView>
          }
        />
        <Route
          path='shipment/:id/view'
          element={
            <SuspensedView>
              <ShipmentInfoPage />
            </SuspensedView>
          }
        />
        <Route
          path='my-profile'
          element={
            <SuspensedView>
              <MyProfilePage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
