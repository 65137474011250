import {useEffect, useRef, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {fbsdb} from '../../utils/firebase'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import Organization from '../../models/Organization'
import {Alert, Button, Spinner} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import OrganizationGroup from '../../models/OrganizationGroup'
import {capitalizeFirst} from '../../utils/common'
import SupplierGroupModal from './SupplierGroupModal'
import RemoveConfirmationModal from '../../widgets/RemoveConfirmationModal'
import OrganizationBillingGroup from '../../models/OrganizationBillingGroup'
import SupplierBillingGroupModal from './SupplierBillingGroupModal'

const organizationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  note: Yup.string(),
  type: Yup.string().required('Type is required'),
  color: Yup.string(),
})
const organizationDB = 'organizations'
const organizationBillingDB = 'organization_billing_group'
const groupDB = 'groups'

const SupplierManagement: React.FC = () => {
  const params = useParams()
  const sid = params.id ? params.id.toString() : undefined
  const navigate = useNavigate()
  const initialValues: Organization = {
    name: '',
    note: '',
    type: 'supplier',
    color: '',
  }

  const orgName = useRef('')
  const [data, setData] = useState<Organization>(initialValues)
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [groupLoading, setGroupLoading] = useState(false)
  const [billingGroupLoading, setBillingGroupLoading] = useState(false)
  const [alert, setAlert] = useState({isAlert: false, msg: '', variant: 'primary'})
  const init: any[] = []
  const [groups, setGroups] = useState<OrganizationGroup[]>(init)
  const [billingGroups, setBillingGroups] = useState<OrganizationBillingGroup[]>(init)
  const selectedGroup = useRef<OrganizationGroup | undefined>(undefined)
  const selectedBillingGroup = useRef<OrganizationBillingGroup | undefined>(undefined)
  const deleteType = useRef('')

  // for supplier group management
  const [modalGroupShow, setModalGroupShow] = useState(false)
  const ModalGroupCloseHandler = () => setModalGroupShow(false)
  const ModalGroupShowHandler = (isNew: boolean, groudId: string | undefined) => {
    if (isNew) {
      selectedGroup.current = undefined
    } else {
      if (groudId) {
        selectedGroup.current = groups.find((e) => e.id === groudId)
      }
    }
    setModalGroupShow(true)
  }
  const ModalGroupSaveHandler = async () => {
    setModalGroupShow(false)
    loadGroups()
  }

  const [modalDeleteShow, setModalDeleteShow] = useState(false)
  const deleteMessage = useRef('')
  const ModalDeleteCloseHandler = () => setModalDeleteShow(false)
  const ModalDeleteShowHandler = (id: string | undefined, type: string) => {
    deleteType.current = type
    if (id) {
      if (type === 'subgroup') {
        const sg = groups.find((e) => e.id === id)
        selectedGroup.current = sg
        deleteMessage.current = sg?.name ? sg.name : ''
        setModalDeleteShow(true)
      } else if (type === 'billinggroup') {
        const bg = billingGroups.find((e) => e.id === id)
        selectedBillingGroup.current = bg
        deleteMessage.current = bg?.name ? bg.name : ''
        setModalDeleteShow(true)
      }
    }
  }
  const ModalDeleteConfirmHandler = async () => {
    if (deleteType.current === 'subgroup') {
      if (selectedGroup.current) {
        const groupDocRef = doc(
          fbsdb,
          organizationDB + '/' + sid + '/' + groupDB,
          selectedGroup.current.id ? selectedGroup.current.id : ''
        )
        await deleteDoc(groupDocRef)
        loadGroups()
      }
    } else if (deleteType.current === 'billinggroup') {
      if (selectedBillingGroup.current) {
        const bgDocRef = doc(
          fbsdb,
          organizationBillingDB,
          selectedBillingGroup.current.id ? selectedBillingGroup.current.id : ''
        )
        await deleteDoc(bgDocRef)
        loadBillingGroups()
      }
    }

    setModalDeleteShow(false)
  }

  // for billing group modal management
  const [modalBillingGroupShow, setModalBillingGroupShow] = useState(false)
  const ModalBillingGroupCloseHandler = () => setModalBillingGroupShow(false)
  const ModalBillingGroupShowHandler = (isNew: boolean, billingGroudId: string | undefined) => {
    if (isNew) {
      selectedBillingGroup.current = undefined
    } else {
      if (billingGroudId) {
        selectedBillingGroup.current = billingGroups.find((e) => e.id === billingGroudId)
      }
    }
    setModalBillingGroupShow(true)
  }
  const ModalBillingGroupSaveHandler = async () => {
    setModalBillingGroupShow(false)
    loadBillingGroups()
  }

  const formik = useFormik<Organization>({
    initialValues,
    validationSchema: organizationSchema,
    onSubmit: (values) => {
      setLoading(true)
      setAlert({isAlert: false, msg: '', variant: 'primary'})
      // values.dateCreated = new Date()
      const updatedData = Object.assign(data, values)
      setData(updatedData)
      if (editMode) {
        editOrganization(updatedData)
      } else {
        addOrganization(updatedData)
      }
      setLoading(false)
    },
  })

  const updateData = (fieldsToUpdate: Partial<Organization>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  useEffect(() => {
    if (sid) {
      loadGroups()
      loadBillingGroups()
      setEditMode(true)
      const doDocRef = doc(fbsdb, organizationDB, sid)
      onSnapshot(doDocRef, (doc) => {
        const supplier = doc.data() as any
        supplier.id = sid
        orgName.current = supplier.name
        updateData(supplier)
        // formik.setValues(usr)
        formik.setValues(data)
      })
    } else {
      formik.setValues(initialValues)
      setGroups([])
      setBillingGroups([])
      setEditMode(false)
    }
  }, [sid])

  const loadGroups = async () => {
    setGroupLoading(true)
    const q = await getDocs(query(collection(fbsdb, organizationDB + '/' + sid + '/' + groupDB)))
    const objList: any[] = q.docs.map((doc: any) => {
      const obj = doc.data() as any
      obj.id = doc.id

      return obj
    })

    objList.sort((a, b) => a.name.localeCompare(b.name))

    setGroups(objList)
    setGroupLoading(false)
  }

  const loadBillingGroups = async () => {
    setBillingGroupLoading(true)
    const q = await getDocs(
      query(collection(fbsdb, organizationBillingDB), where('organizationId', '==', sid))
    )
    const objList: any[] = q.docs.map((doc: any) => {
      const obj = doc.data() as any
      obj.id = doc.id

      return obj
    })
    objList.sort((a, b) => a.name.localeCompare(b.name))

    setBillingGroups(objList)
    setBillingGroupLoading(false)
  }

  const addOrganization = async (updatedData: Organization) => {
    try {
      const object = await addDoc(collection(fbsdb, organizationDB), {
        name: updatedData.name,
        note: updatedData.note,
        type: updatedData.type,
        color: updatedData.color,
      })
      const subObj = await addDoc(
        collection(fbsdb, organizationDB + '/' + object.id + '/' + groupDB),
        {
          name: updatedData.name,
          color: updatedData.color,
          type: 'primary',
        }
      )

      const obj = {
        default: true,
        order: 2,
        name: updatedData.name,
        organizationId: object.id,
        organizationName: updatedData.name,
        groupIds: [subObj.id],
        groups: [{id: subObj.id, name: updatedData.name}],
      }
      await addDoc(collection(fbsdb, organizationBillingDB), obj)

      setAlert({
        isAlert: true,
        msg: 'New Supplier Group / Organization Added!',
        variant: 'success',
      })

      navigate(`/management/suppliers/${object.id}/edit`)
    } catch (e) {
      console.log(e)
      setAlert({
        isAlert: true,
        msg: 'Failed to add new Supplier Group / Organization.',
        variant: 'danger',
      })
    }

    orgName.current = updatedData.name
    setLoading(false)
  }

  const editOrganization = async (updatedData: Organization) => {
    try {
      if (sid) {
        await updateDoc(doc(fbsdb, organizationDB, sid), {
          name: updatedData.name,
          note: updatedData.note,
          type: updatedData.type,
          color: updatedData.color,
        })
      }

      orgName.current = updatedData.name

      setAlert({
        isAlert: true,
        msg: 'Supplier Group / Organization Updated!',
        variant: 'success',
      })
    } catch (e) {
      console.log(e)
      setAlert({
        isAlert: true,
        msg: 'Failed to update supplier group / Organization.',
        variant: 'danger',
      })
    }

    setLoading(false)
  }

  return (
    <>
      {alert.isAlert && (
        <Alert key='danger' variant={alert.variant}>
          {alert.msg}{' '}
          <Link className='fw-bolder' to={'/management/suppliers/list'}>
            Back to supplier group list
          </Link>
        </Alert>
      )}
      <SupplierGroupModal
        modalShow={modalGroupShow}
        group={selectedGroup.current}
        sid={sid}
        ModalSaveHandler={ModalGroupSaveHandler}
        ModalCloseHandler={ModalGroupCloseHandler}
      />
      <SupplierBillingGroupModal
        modalShow={modalBillingGroupShow}
        billingGroup={selectedBillingGroup.current}
        sid={sid}
        sname={orgName.current}
        ModalSaveHandler={ModalBillingGroupSaveHandler}
        ModalCloseHandler={ModalBillingGroupCloseHandler}
      />
      <RemoveConfirmationModal
        message={deleteMessage.current}
        modalShow={modalDeleteShow}
        ModalConfirmHandler={ModalDeleteConfirmHandler}
        ModalCloseHandler={ModalDeleteCloseHandler}
      />
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>
              {editMode ? 'Edit Supplier / Organization' : 'New  Supplier / Organization'}
            </h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Organization / Supplier Group Name
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Name'
                        {...formik.getFieldProps('name')}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.name}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Note</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Note'
                    {...formik.getFieldProps('note')}
                  />
                  {formik.touched.note && formik.errors.note && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.note}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Color Tag</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='color #'
                    {...formik.getFieldProps('color')}
                  />
                  {formik.touched.color && formik.errors.color && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.color}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Type</label>

                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg'
                    {...formik.getFieldProps('type')}
                  >
                    <option value='supplier'>Supplier</option>
                    <option value='organization'>Organization</option>
                  </select>
                  {formik.touched.type && formik.errors.type && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.type}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Suppliers / Sub Group{' '}
              {groupLoading && <Spinner animation='border' variant='warning' size='sm' />}
            </span>
            <span className='text-muted mt-1 fw-semibold fs-7'></span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <Button
              onClick={() => {
                ModalGroupShowHandler(true, '')
              }}
              className='btn btn-sm btn-light-warning'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              New Supplier
            </Button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Name</th>
                  <th className='min-w-140px'>type</th>
                  <th className='min-w-100px text-end'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {groups.map((group) => (
                  <tr key={group.id}>
                    <td>
                      <div className='text-dark fw-bold d-block fs-6'>{group.name}</div>
                    </td>
                    <td>
                      <div className='text-dark fs-6'>{capitalizeFirst(group.type)}</div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <Button
                          onClick={() => {
                            ModalGroupShowHandler(false, group.id)
                          }}
                          variant='outline-warning'
                          size='sm'
                          className='btn-icon me-1'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen055.svg'
                            className='svg-icon-3'
                          />
                        </Button>
                        {group.type !== 'primary' && (
                          <Button
                            variant='outline-danger'
                            onClick={() => {
                              ModalDeleteShowHandler(group.id, 'subgroup')
                            }}
                            size='sm'
                            className='btn-icon me-1'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen040.svg'
                              className='svg-icon-3'
                            />
                          </Button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <div className={`card mb-5 mb-xl-8`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Billing Groups / Categories{' '}
              {groupLoading && <Spinner animation='border' variant='warning' size='sm' />}
            </span>
            <span className='text-muted mt-1 fw-semibold fs-7'></span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <Button
              onClick={() => {
                ModalBillingGroupShowHandler(true, '')
              }}
              className='btn btn-sm btn-light-warning'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              New Billing Group
            </Button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Name</th>
                  <th className='min-w-140px'>sub suppliers</th>
                  <th className='min-w-100px text-end'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {billingGroups.map((bg) => (
                  <tr key={bg.id}>
                    <td>
                      <div className='text-dark fw-bold d-block fs-6'>{bg.name}</div>
                    </td>
                    <td>
                      <div className='text-dark fw-bold fs-8'>
                        {bg.groups.map((sg: any) => (
                          <span className='me-2' key={sg.id}>
                            {sg.name}
                          </span>
                        ))}
                      </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <Button
                          onClick={() => {
                            ModalBillingGroupShowHandler(false, bg.id)
                          }}
                          variant='outline-warning'
                          size='sm'
                          className='btn-icon me-1'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen055.svg'
                            className='svg-icon-3'
                          />
                        </Button>
                        <Button
                          variant='outline-danger'
                          onClick={() => {
                            ModalDeleteShowHandler(bg.id, 'billinggroup')
                          }}
                          size='sm'
                          className='btn-icon me-1'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen040.svg'
                            className='svg-icon-3'
                          />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='fw-bold text-muted'>
            * Billing group only take effect of future data, if you need to previous billing data
            after edit the billing group, please contact the developer.
          </div>
        </div>
      </div>
    </>
  )
}

export default SupplierManagement
