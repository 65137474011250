import {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {fbsdb} from '../../utils/firebase'
import {addDoc, collection, doc, onSnapshot, updateDoc} from 'firebase/firestore'
import ShipmentProblem from '../../models/ShipmentProblem'
import {Alert} from 'react-bootstrap'

const shipmentProblemSchema = Yup.object().shape({
  problemcode: Yup.string().required('Area Code is required'),
  problemdesc: Yup.string().required('Area Code is required'),
})
const shipmentProblemDB = 'shipment_problems'

const ProblemShipmentManagement: React.FC = () => {
  const params = useParams()
  const pid = params.id ? params.id.toString() : undefined

  const initialValues: ShipmentProblem = {
    problemcode: '',
    problemdesc: '',
  }

  const [data, setData] = useState<ShipmentProblem>(initialValues)
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState({isAlert: false, msg: '', variant: 'primary'})

  const formik = useFormik<ShipmentProblem>({
    initialValues,
    validationSchema: shipmentProblemSchema,
    onSubmit: (values) => {
      setLoading(true)
      setAlert({isAlert: false, msg: '', variant: 'primary'})
      // values.dateCreated = new Date()
      const updatedData = Object.assign(data, values)
      setData(updatedData)
      if (editMode) {
        editProblem(updatedData)
      } else {
        addProblem(updatedData)
      }
      setLoading(false)
    },
  })

  const updateData = (fieldsToUpdate: Partial<ShipmentProblem>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  useEffect(() => {
    if (pid) {
      setEditMode(true)
      const doDocRef = doc(fbsdb, shipmentProblemDB, pid)
      onSnapshot(doDocRef, (doc) => {
        const prob = doc.data() as any
        prob.id = pid
        updateData(prob)
        // formik.setValues(usr)
        formik.setValues(data)
      })
    }
  }, [])

  const addProblem = async (updatedData: ShipmentProblem) => {
    try {
      await addDoc(collection(fbsdb, shipmentProblemDB), {
        problemcode: updatedData.problemcode,
        problemdesc: updatedData.problemdesc,
      })

      setAlert({
        isAlert: true,
        msg: 'New problem added!',
        variant: 'success',
      })

      formik.setValues(initialValues)
    } catch (e) {
      console.log(e)
      setAlert({
        isAlert: true,
        msg: 'Failed to add new problem.',
        variant: 'danger',
      })
    }

    setLoading(false)
  }

  const editProblem = async (updatedData: ShipmentProblem) => {
    try {
      if (pid) {
        await updateDoc(doc(fbsdb, shipmentProblemDB, pid), {
          problemcode: updatedData.problemcode,
          problemdesc: updatedData.problemdesc,
        })

        setAlert({
          isAlert: true,
          msg: 'Problem updated!',
          variant: 'success',
        })
      }
    } catch (e) {
      console.log(e)
      setAlert({
        isAlert: true,
        msg: 'Update problem failed.',
        variant: 'danger',
      })
    }

    setLoading(false)
  }

  return (
    <>
      {alert.isAlert && (
        <Alert key='danger' variant={alert.variant}>
          {alert.msg}{' '}
          <Link className='fw-bolder' to={'/management/shipment-problems/list'}>
            Back to shipment problem list
          </Link>
        </Alert>
      )}
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>
              {editMode ? 'Edit Shipment Problem' : 'New Shipment Problem'}
            </h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Problem Code
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Problem Code'
                        {...formik.getFieldProps('problemcode')}
                      />
                      {formik.touched.problemcode && formik.errors.problemcode && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.problemcode}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Description</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Problem Description'
                    {...formik.getFieldProps('problemdesc')}
                  />
                  {formik.touched.problemdesc && formik.errors.problemdesc && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.problemdesc}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default ProblemShipmentManagement
