import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import DeliverAreaManagement from './DeliveryAreaManagement'
import DeliverAreaList from './DeliveryAreas'
import DepartmentManagement from './DepartmentManagement'
import DepartmentList from './Departments'
import ECPList from './ECPList'
import ECPManagement from './ECPManagement'
import ProblemShipmentManagement from './ProblemShipmentManagement'
import ProblemShipmentList from './ProblemShipments'
import StationManagement from './StationManagement'
import StationList from './Stations'
import SupplierManagement from './SupplierManagement'
import SupplierList from './Suppliers'
import StateCityList from './StateCities'
import StateCityManagement from './StateCityManagement'
import StateList from './States'

const ManagementPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/areas/list'
          element={
            <>
              <DeliverAreaList />
            </>
          }
        />
        <Route
          path='/areas/new'
          element={
            <>
              <DeliverAreaManagement />
            </>
          }
        />
        <Route
          path='/areas/:id/edit'
          element={
            <>
              <DeliverAreaManagement />
            </>
          }
        />
        <Route path='ecp/list' element={<ECPList />} />
        <Route path='ecp/new' element={<ECPManagement />} />
        <Route path='ecp/:id/edit' element={<ECPManagement />} />
        <Route path='suppliers/list' element={<SupplierList />} />
        <Route path='suppliers/new' element={<SupplierManagement />} />
        <Route path='suppliers/:id/edit' element={<SupplierManagement />} />
        <Route path='departments/list' element={<DepartmentList />} />
        <Route path='departments/new' element={<DepartmentManagement />} />
        <Route path='departments/:id/edit' element={<DepartmentManagement />} />
        <Route path='stations/list' element={<StationList />} />
        <Route path='stations/new' element={<StationManagement />} />
        <Route path='stations/:id/edit' element={<StationManagement />} />
        <Route path='cities/list' element={<StateCityList />} />
        <Route path='cities/new' element={<StateCityManagement />} />
        <Route path='cities/:id/edit' element={<StateCityManagement />} />
        <Route path='states/list' element={<StateList />} />
        <Route path='shipment-problems/list' element={<ProblemShipmentList />} />
        <Route path='shipment-problems/new' element={<ProblemShipmentManagement />} />
        <Route path='shipment-problems/:id/edit' element={<ProblemShipmentManagement />} />
      </Route>

      <Route index element={<Navigate to='/dashboard' />} />
    </Routes>
  )
}

export default ManagementPage
