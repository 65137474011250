import {useEffect, useMemo, useRef, useState, } from 'react'
import {Button, Spinner} from 'react-bootstrap'
import {Link, NavigationType, useNavigate, useNavigationType} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl, useDebounce} from '../../../_metronic/helpers'
import {fbsdb} from '../../utils/firebase'
import {collection, deleteDoc, doc, getDocs, query, where} from 'firebase/firestore'
import {states} from '../../utils/common'
import CustomPagination from '../user-management/users-list/Pagination'
import Shop from '../../models/Shop'
import {useAuth} from '../auth'
import RemoveConfirmationModal from '../../widgets/RemoveConfirmationModal'
import { Action } from 'history';

const shopDB = 'shops'
const areaDB = 'delivery_areas'
let PageSize = 30

const ECPList: React.FC = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const init: any[] = []
  const [data, setData] = useState(init)
  const raws = useRef(init)
  const [shops, setShops] = useState(init)
  const [areas, setAreas] = useState(init)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  // const [selectedState, setSelectedState] = useState('KUL')
  const [selectedArea, setSelectedArea] = useState('')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  // const navType: NavigationType = useNavigationType();
  // useEffect(() => {
  //   if (navType === Action.Pop) {
  //     console.log("Back button used. Not running stuff");
  //     console.log(shops)
  //   } else console.log("useEffect called in home");
  // }, []);

  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        filterECP()
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )

  useEffect(() => {
    setIsLoading(true)
    loadShops()
  }, [selectedArea])

  useEffect(() => {
    loadAreas()
  }, [])

  // const stateHandler = (event: any) => {
  //   setSelectedState(event.target.value)
  // }

  const areaHandler = (event: any) => {
    setSelectedArea(event.target.value)
  }

  const loadAreas = async () => {
    const q = await getDocs(query(collection(fbsdb, areaDB)))
    const objList: any[] = q.docs.map((doc: any) => {
      const obj = doc.data() as any
      obj.id = doc.id

      return obj
    })

    objList.sort((a, b) => a.areacode.localeCompare(b.areacode))

    setAreas(objList)
  }

  const loadShops = async () => {
    let q
    if (selectedArea) {
      q = await getDocs(query(collection(fbsdb, shopDB), where('areaId', '==', selectedArea)))
    } else {
      q = await getDocs(query(collection(fbsdb, shopDB)))
    }
    const objList: any[] = q.docs.map((doc: any) => {
      const obj = doc.data() as any
      obj.id = doc.id

      return obj
    })

    objList.sort((a, b) => a.name.localeCompare(b.name))

    raws.current = objList
    filterECP()

    // setData(objList)
  }

  const filterECP = () => {
    setIsLoading(true)
    if (debouncedSearchTerm) {
      const fd = raws.current.filter((ecp: any) => {
        return ecp.name.toLowerCase().indexOf(debouncedSearchTerm.toLowerCase()) > -1
      })

      setData(fd)
    } else {
      setData(raws.current)
    }
    setIsLoading(false)
  }

  useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    setShops(data.slice(firstPageIndex, lastPageIndex))
    setIsLoading(false)
  }, [currentPage, data])

  const loadStateName = (code: string) => {
    const s = states.find((e) => e.code === code)

    if (s) {
      return s.name
    } else {
      return '-'
    }
  }

  const [selectedECP, setSelectedECP] = useState<Shop | undefined>(undefined)
  const [modalDeleteShow, setModalDeleteShow] = useState(false)
  const [deleteMessage, setDeleteMessage] = useState('')
  const ModalDeleteCloseHandler = () => setModalDeleteShow(false)
  const ModalDeleteShowHandler = (sid: string | undefined) => {
    if (sid) {
      const sh = shops.find((e) => e.id === sid)
      setSelectedECP(sh)
      setDeleteMessage(sh?.name ? sh.name : '')
      setModalDeleteShow(true)
    }
  }
  const ModalDeleteConfirmHandler = async () => {
    if (selectedECP) {
      const groupDocRef = doc(fbsdb, shopDB + '/' + selectedECP.id)
      await deleteDoc(groupDocRef)
    }

    setModalDeleteShow(false)
    loadShops()
  }

  return (
    <>
      <RemoveConfirmationModal
        message={deleteMessage}
        modalShow={modalDeleteShow}
        ModalConfirmHandler={ModalDeleteConfirmHandler}
        ModalCloseHandler={ModalDeleteCloseHandler}
      />
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              ECP List {isLoading && <Spinner animation='border' variant='warning' size='sm' />}
            </span>
            <span className='text-muted mt-1 fw-semibold fs-7'></span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add ECP'
          >
            {/* <div className='me-4'>
              <select
                name='state'
                onChange={stateHandler}
                defaultValue={selectedState}
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-sm form-select-white w-125px'
              >
                <option key='all' value=''>
                  All
                </option>
                {states.map((state) => (
                  <option key={state.code} value={state.code}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div> */}
            <div className='me-6'>
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-300px ps-14 me-3'
                placeholder='Search by ECP Name'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <select
                name='area'
                onChange={areaHandler}
                defaultValue={selectedArea}
                data-control='select3'
                data-hide-search='true'
                className='form-select form-select-sm form-select-white w-125px'
              >
                <option key='all' value=''>
                  Delivery Areas
                </option>
                {areas.map((area) => (
                  <option key={area.id} value={area.id}>
                    {area.areacode} {area.name}
                  </option>
                ))}
              </select>
            </div>
            <Button
              onClick={() => navigate('/management/ecp/new')}
              className='btn btn-sm btn-light-primary'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              New ECP
            </Button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-350px'>Name</th>
                  <th className='min-w-150px'>State</th>
                  <th className='min-w-150px'>Delivery Area</th>
                  <th className='min-w-120px'>Supplier</th>
                  <th className='min-w-100px text-end'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {shops.map((shop) => (
                  <tr key={shop.id}>
                    <td>
                      <Link
                        to={'/management/ecp/' + shop.id + '/edit'}
                        className='text-dark fw-bold text-hover-primary d-block fs-6'
                      >
                        {shop.name}
                      </Link>
                    </td>
                    <td>
                      <div className='text-dark fw-bold d-block fs-6'>
                        {loadStateName(shop.state)}
                      </div>
                    </td>
                    <td>
                      <div className='text-dark fw-bold d-block fs-6'>{shop.areaCode}</div>
                    </td>
                    <td>
                      <div className='text-dark fw-bold fs-8'>
                        {shop.organizations.map((org: any) => (
                          <span className='me-2' key={org.id}>
                            {org.name}
                          </span>
                        ))}
                      </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <Link
                          to={'/management/ecp/' + shop.id + '/edit'}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </Link>
                        {currentUser && (
                          <>
                            {(currentUser.role === 'admin' ||
                              currentUser.role === 'staff' ||
                              currentUser.role === 'manager') && (
                              <Button
                                variant='outline-danger'
                                onClick={() => {
                                  ModalDeleteShowHandler(shop.id)
                                }}
                                size='sm'
                                className='btn-icon me-1'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen040.svg'
                                  className='svg-icon-3'
                                />
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
            <CustomPagination
              currentPage={currentPage}
              totalCount={data.length}
              pageSize={PageSize}
              onPageChange={(page: number) => setCurrentPage(page)}
            />
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default ECPList
