import {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import DeliveryArea from '../../models/DeliveryArea'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {fbsdb} from '../../utils/firebase'
import {addDoc, collection, doc, onSnapshot, updateDoc} from 'firebase/firestore'
import {Alert} from 'react-bootstrap'

const areaSchema = Yup.object().shape({
  areacode: Yup.string().required('Area Code is required'),
  town: Yup.string().required('Town / City / District is required'),
  note: Yup.string(),
})
const areaDB = 'delivery_areas'

const DeliverAreaManagement: React.FC = () => {
  const params = useParams()
  const aid = params.id ? params.id.toString() : undefined

  const initialValues: DeliveryArea = {
    areacode: '',
    town: '',
    note: '',
  }

  const [data, setData] = useState<DeliveryArea>(initialValues)
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState({isAlert: false, msg: '', variant: 'primary'})

  const formik = useFormik<DeliveryArea>({
    initialValues,
    validationSchema: areaSchema,
    onSubmit: (values) => {
      setLoading(true)
      setAlert({isAlert: false, msg: '', variant: 'primary'})
      // values.dateCreated = new Date()
      const updatedData = Object.assign(data, values)
      setData(updatedData)
      if (editMode) {
        editArea(updatedData)
      } else {
        addArea(updatedData)
      }
    },
  })

  const updateData = (fieldsToUpdate: Partial<DeliveryArea>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  useEffect(() => {
    if (aid) {
      setEditMode(true)
      const doDocRef = doc(fbsdb, areaDB, aid)
      onSnapshot(doDocRef, (doc) => {
        const area = doc.data() as any
        area.id = aid
        updateData(area)
        // formik.setValues(usr)
        formik.setValues(data)
      })
    }
  }, [])

  const addArea = async (updatedData: DeliveryArea) => {
    try {
      await addDoc(collection(fbsdb, areaDB), {
        areacode: updatedData.areacode,
        town: updatedData.town,
        note: updatedData.note,
        dateUpdated: new Date(),
        dateAdded: new Date(),
      })

      setAlert({
        isAlert: true,
        msg: 'New area added!',
        variant: 'success',
      })

      formik.setValues(initialValues)
    } catch (e) {
      console.log(e)
      setAlert({
        isAlert: true,
        msg: 'Failed to add new delivery area.',
        variant: 'danger',
      })
    }

    setLoading(false)
  }

  const editArea = async (updatedData: DeliveryArea) => {
    try {
      if (aid) {
        await updateDoc(doc(fbsdb, areaDB, aid), {
          areacode: updatedData.areacode,
          town: updatedData.town,
          note: updatedData.note,
          dateUpdated: new Date(),
        })

        setAlert({
          isAlert: true,
          msg: 'Area updated!',
          variant: 'success',
        })
      }
    } catch (e) {
      console.log(e)
      setAlert({
        isAlert: true,
        msg: 'Update area failed.',
        variant: 'danger',
      })
    }

    setLoading(false)
  }

  return (
    <>
      {alert.isAlert && (
        <Alert key='danger' variant={alert.variant}>
          {alert.msg}{' '}
          <Link className='fw-bolder' to={'/management/areas/list'}>
            Back to delivery area list
          </Link>
        </Alert>
      )}
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>
              {editMode ? 'Edit Delivery Area' : 'New Delivery Area'}
            </h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Area Code</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Area Code'
                        {...formik.getFieldProps('areacode')}
                      />
                      {formik.touched.areacode && formik.errors.areacode && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.areacode}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Town / City / District</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Town'
                    {...formik.getFieldProps('town')}
                  />
                  {formik.touched.town && formik.errors.town && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.town}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Note</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Note'
                    {...formik.getFieldProps('note')}
                  />
                  {formik.touched.note && formik.errors.note && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.note}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default DeliverAreaManagement
