/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  return (
    <>
      {currentUser && (
        <>
          {(currentUser.role === 'admin' ||
            currentUser.role === 'staff' ||
            currentUser.role === 'manager') && (
            <>
              <SidebarMenuItem
                to='/dashboard'
                icon='/media/icons/duotune/general/gen001.svg'
                title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                fontIcon='bi-app-indicator'
              />
              <SidebarMenuItem
                to='/shipments/list'
                icon='/media/icons/duotune/ecommerce/ecm007.svg'
                title='Shipments'
                fontIcon='bi-app-indicator'
              />
              <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                    Transaction
                  </span>
                </div>
              </div>
              <SidebarMenuItemWithSub
                to='/transactions/checkinawb/list'
                title='Check In AWB'
                icon='/media/icons/duotune/arrows/arr076.svg'
                fontIcon='bi-person'
              >
                <SidebarMenuItem
                  to='/transactions/checkinawb/new'
                  title='New Check In AWB'
                  hasBullet={true}
                />
                <SidebarMenuItem
                  to='/transactions/checkinawb/list'
                  title='Manage Check In AWB'
                  hasBullet={true}
                />
              </SidebarMenuItemWithSub>
              <SidebarMenuItemWithSub
                to='/transactions/deliveryrunsheet/list'
                title='Delivery Runsheet'
                icon='/media/icons/duotune/arrows/arr096.svg'
                fontIcon='bi-layers'
              >
                <SidebarMenuItem
                  to='/transactions/deliveryrunsheet/new'
                  title='New Delivery Runsheet'
                  hasBullet={true}
                />
                <SidebarMenuItem
                  to='/transactions/deliveryrunsheet/list'
                  title='Manage Delivery Runsheet'
                  hasBullet={true}
                />
              </SidebarMenuItemWithSub>
              <SidebarMenuItemWithSub
                to='/transactions/problemshipment/list'
                title='Problem Shipment'
                icon='/media/icons/duotune/arrows/arr058.svg'
                fontIcon='bi-layers'
              >
                <SidebarMenuItem
                  to='/transactions/problemshipment/new'
                  title='New Problem Shipment'
                  hasBullet={true}
                />
                <SidebarMenuItem
                  to='/transactions/problemshipment/list'
                  title='Manage Problem Shipment'
                  hasBullet={true}
                />
              </SidebarMenuItemWithSub>
              {(currentUser.role === 'admin' || currentUser.role === 'manager') && (
                <SidebarMenuItemWithSub
                  to='/transactions/pod/list'
                  title='POD'
                  icon='/media/icons/duotune/files/fil008.svg'
                  fontIcon='bi-layers'
                >
                  <SidebarMenuItem to='/transactions/pod/new' title='New POD' hasBullet={true} />
                  <SidebarMenuItem
                    to='/transactions/pod/list'
                    title='Manage PODs'
                    hasBullet={true}
                  />
                </SidebarMenuItemWithSub>
              )}

              <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                    Management
                  </span>
                </div>
              </div>
              <SidebarMenuItemWithSub
                to='/users/list'
                title='Users'
                fontIcon='bi-chat-left'
                icon='/media/icons/duotune/communication/com014.svg'
              >
                <SidebarMenuItem to='/users/new' title='New User' hasBullet={true} />
                <SidebarMenuItem to='/users/list' title='Manage User' hasBullet={true} />
              </SidebarMenuItemWithSub>
              <SidebarMenuItemWithSub
                to='/management/departments/list'
                title='Staff Department/Group'
                fontIcon='bi-chat-left'
                icon='/media/icons/duotune/communication/com005.svg'
              >
                <SidebarMenuItem
                  to='/management/departments/new'
                  title='New Department'
                  hasBullet={true}
                />
                <SidebarMenuItem
                  to='/management/departments/list'
                  title='Manage Departments'
                  hasBullet={true}
                />
              </SidebarMenuItemWithSub>
              <SidebarMenuItemWithSub
                to='/management/areas/list'
                title='Delivery Area'
                fontIcon='bi-chat-left'
                icon='/media/icons/duotune/general/gen018.svg'
              >
                <SidebarMenuItem
                  to='/management/areas/new'
                  title='New Delivery Area'
                  hasBullet={true}
                />
                <SidebarMenuItem
                  to='/management/areas/list'
                  title='Manage Delivery Areas'
                  hasBullet={true}
                />
              </SidebarMenuItemWithSub>
              <SidebarMenuItemWithSub
                to='/management/suppliers/list'
                title='Supplier Groups'
                fontIcon='bi-chat-left'
                icon='/media/icons/duotune/general/gen003.svg'
              >
                <SidebarMenuItem
                  to='/management/suppliers/new'
                  title='New Supplier Group'
                  hasBullet={true}
                />
                <SidebarMenuItem
                  to='/management/suppliers/list'
                  title='Manage Supplier Groups'
                  hasBullet={true}
                />
              </SidebarMenuItemWithSub>
              <SidebarMenuItemWithSub
                to='/management/stations/list'
                title='Station Profile'
                fontIcon='bi-chat-left'
                icon='/media/icons/duotune/ecommerce/ecm006.svg'
              >
                <SidebarMenuItem
                  to='/management/stations/new'
                  title='New Station Profile'
                  hasBullet={true}
                />
                <SidebarMenuItem
                  to='/management/stations/list'
                  title='Manage Station Profile'
                  hasBullet={true}
                />
              </SidebarMenuItemWithSub>
              <SidebarMenuItemWithSub
                to='/management/ecp/list'
                title='ECPs'
                fontIcon='bi-chat-left'
                icon='/media/icons/duotune/ecommerce/ecm004.svg'
              >
                <SidebarMenuItem to='/management/ecp/new' title='New ECP' hasBullet={true} />
                <SidebarMenuItem to='/management/ecp/list' title='Manage ECPs' hasBullet={true} />
              </SidebarMenuItemWithSub>
              <SidebarMenuItemWithSub
                to='/management/shipment-problems/list'
                title='Shipment Problems'
                fontIcon='bi-chat-left'
                icon='/media/icons/duotune/general/gen046.svg'
              >
                <SidebarMenuItem
                  to='/management/shipment-problems/new'
                  title='New Shipment Problem'
                  hasBullet={true}
                />
                <SidebarMenuItem
                  to='/management/shipment-problems/list'
                  title='Manage Shipment Problems'
                  hasBullet={true}
                />
              </SidebarMenuItemWithSub>
              <SidebarMenuItemWithSub
                to='/management/cities/list'
                title='Cities & States'
                fontIcon='bi-chat-left'
                icon='/media/icons/duotune/maps/map009.svg'
              >
                <SidebarMenuItem
                  to='/management/cities/new'
                  title='New City / Town / District'
                  hasBullet={true}
                />
                <SidebarMenuItem
                  to='/management/cities/list'
                  title='Manage City / Town / District'
                  hasBullet={true}
                />
                <SidebarMenuItem
                  to='/management/states/list'
                  title='Manage States'
                  hasBullet={true}
                />
              </SidebarMenuItemWithSub>              
            </>
          )}
          {currentUser.role === 'driver' && (
            <>
              <SidebarMenuItem
                to='/drivers/deliveryrunsheets'
                icon='/media/icons/duotune/art/art002.svg'
                title='Delivery Runsheets'
                fontIcon='bi-app-indicator'
              />
              <SidebarMenuItem
                to='/drivers/pods'
                icon='/media/icons/duotune/ecommerce/ecm006.svg'
                title='My PODs'
                fontIcon='bi-app-indicator'
              />
              <SidebarMenuItem
                to='/drivers/checkinlist'
                icon='/media/icons/duotune/maps/map008.svg'
                title='My Checkin Record'
                fontIcon='bi-app-indicator'
              />
              <SidebarMenuItem
                to='/drivers/commission'
                icon='/media/icons/duotune/finance/fin010.svg'
                title='My Commission'
                fontIcon='bi-app-indicator'
              />
            </>
          )}
          {currentUser.role === 'customer' && (
            <>
              <SidebarMenuItem
                to='/customers/request-pickup'
                icon='/media/icons/duotune/ecommerce/ecm006.svg'
                title='Request Pickup'
                fontIcon='bi-app-indicator'
              />
              <SidebarMenuItem
                to='/customers/pickup-list'
                icon='/media/icons/duotune/general/gen005.svg'
                title='Pickup List'
                fontIcon='bi-app-indicator'
              />
              <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Monthly</span>
                </div>
              </div>
              <SidebarMenuItem
                to='/customers/billing-report'
                icon='/media/icons/duotune/finance/fin010.svg'
                title='Biling Report'
                fontIcon='bi-app-indicator'
              />
              <SidebarMenuItem
                to='/customers/kpi-report'
                icon='/media/icons/duotune/graphs/gra003.svg'
                title='KPI Report'
                fontIcon='bi-app-indicator'
              />
              <SidebarMenuItem
                to='/customers/orders-report'
                icon='/media/icons/duotune/general/gen017.svg'
                title='Orders Report'
                fontIcon='bi-app-indicator'
              />
              <SidebarMenuItem
                to='/customers/droppoints-report'
                icon='/media/icons/duotune/maps/map008.svg'
                title='Droppoints Report'
                fontIcon='bi-app-indicator'
              />
            </>
          )}
        </>
      )}
    </>
  )
}

export {SidebarMenuMain}
