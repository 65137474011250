import {useEffect, useRef, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {fbsdb} from '../../utils/firebase'
import {collection, deleteDoc, doc, getDocs, query} from 'firebase/firestore'
import City from '../../models/City'
import {useAuth} from '../auth'
import RemoveConfirmationModal from '../../widgets/RemoveConfirmationModal'

const stateCitieseDB = 'state_cities'
const stateDB = 'states'

const StateCityList: React.FC = () => {
  const navigate = useNavigate()
  const init: any[] = []
  const [cities, setCities] = useState(init)
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser} = useAuth()
  const [states, setStates] = useState(init)
  const [selectedState, setSelectedState] = useState('')
  const allCities = useRef(init)

  useEffect(() => {
    loadCities()
    loadStates()
  }, [])

  const loadCities = async () => {
    setIsLoading(true)
    const q = await getDocs(query(collection(fbsdb, stateCitieseDB)))
    const objList: any[] = q.docs.map((doc: any) => {
      const obj = doc.data() as any
      obj.id = doc.id

      return obj
    })

    objList.sort((a, b) => a.name.localeCompare(b.name))

    allCities.current = objList
    setCities(objList)
    setIsLoading(false)
  }

  const loadStates = async () => {
    const q = await getDocs(query(collection(fbsdb, stateDB)))
    const objList: any[] = q.docs.map((doc: any) => {
      const obj = doc.data() as any
      obj.id = doc.id

      return obj
    })

    setStates(objList)
  }

  const stateHandler = (event: any) => {
    setSelectedState(event.target.value)
    const filtered = allCities.current.filter((doc: any) => {
      return doc.stateId === event.target.value
    })

    setCities(filtered)
  }

  const [selectedCity, setSelectedStation] = useState<City | undefined>(undefined)
  const [modalDeleteShow, setModalDeleteShow] = useState(false)
  const [deleteMessage, setDeleteMessage] = useState('')
  const ModalDeleteCloseHandler = () => setModalDeleteShow(false)
  const ModalDeleteShowHandler = (cid: string | undefined) => {
    if (cid) {
      const sta = cities.find((e) => e.id === cid)
      setSelectedStation(sta)
      setDeleteMessage(sta?.name ? sta.name : '')
      setModalDeleteShow(true)
    }
  }
  const ModalDeleteConfirmHandler = async () => {
    if (selectedCity) {
      const groupDocRef = doc(fbsdb, stateCitieseDB + '/' + selectedCity.id)
      await deleteDoc(groupDocRef)
    }

    setModalDeleteShow(false)
    loadCities()
  }

  return (
    <>
      <RemoveConfirmationModal
        message={deleteMessage}
        modalShow={modalDeleteShow}
        ModalConfirmHandler={ModalDeleteConfirmHandler}
        ModalCloseHandler={ModalDeleteCloseHandler}
      />
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              City / Town / Distict{' '}
              {isLoading && <Spinner animation='border' variant='warning' size='sm' />}
            </span>
            <span className='text-muted mt-1 fw-semibold fs-7'></span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <div className='me-6'>
              <select
                name='area'
                onChange={stateHandler}
                defaultValue={selectedState}
                data-control='select3'
                data-hide-search='true'
                className='form-select form-select-sm form-select-white w-125px'
              >
                <option key='all' value=''>
                  All States
                </option>
                {states.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
            <Button
              onClick={() => navigate('/management/cities/new')}
              className='btn btn-sm btn-light-primary'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              New City/Town/District
            </Button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th>City / Town / Distict</th>
                  <th>State</th>
                  <th>Action</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {cities.map((city) => (
                  <tr key={city.id}>
                    <td className='text-dark fw-bold fs-6'>{city.name}</td>
                    <td>
                      <div className='text-dark fw-bold d-block fs-6'>{city.stateName}</div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <Link
                          to={'/management/cities/' + city.id + '/edit'}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </Link>
                        {currentUser && (
                          <>
                            {(currentUser.role === 'admin' ||
                              currentUser.role === 'staff' ||
                              currentUser.role === 'manager') && (
                              <Button
                                variant='outline-danger'
                                onClick={() => {
                                  ModalDeleteShowHandler(city.id)
                                }}
                                size='sm'
                                className='btn-icon me-1'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen040.svg'
                                  className='svg-icon-3'
                                />
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default StateCityList
