import {FirebaseError, initializeApp} from 'firebase/app'
import {getFirestore} from 'firebase/firestore'
import {getAuth, signInWithEmailAndPassword, signOut, UserCredential} from 'firebase/auth'

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
} = process.env

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
}

const app = initializeApp(firebaseConfig)
const fbsdb = getFirestore(app)
const fbsauth = getAuth(app)

const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    const uc = await signInWithEmailAndPassword(fbsauth, email, password)
    return uc.user
  } catch (err) {
    if (err instanceof FirebaseError) {
      throw new Error('Invalid Email / Password.')
    } else {
      throw new Error('Error. Please try again.')
    }
  }
}

const signout = () => {
  signOut(fbsauth)
}

export {fbsdb, fbsauth, logInWithEmailAndPassword, signout}
