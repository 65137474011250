/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Alert} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {useAuth} from '../../modules/auth'
import {capitalizeFirst} from '../../utils/common'

const DashboardPage: FC = () => {
  const {currentUser, logout} = useAuth()

  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
          <Alert variant='primary'>
            <Alert.Heading>Welcome to Yunly Express, {currentUser?.name}</Alert.Heading>
            <p>Your current system role: {capitalizeFirst(currentUser?.role)}</p>
          </Alert>
        </div>
      </div>
      {/* <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget20
          className='h-md-50 mb-5 mb-xl-10'
          description='Active Projects'
          color='#F1416C'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        />

        <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
      </div>
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <ListsWidget6 className='card-xl-stretch mb-5 mb-xl-10' />
      </div>
    </div>

    <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-8'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div> */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
