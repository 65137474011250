import {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {fbsdb} from '../../utils/firebase'
import {addDoc, collection, doc, getDocs, onSnapshot, query, updateDoc} from 'firebase/firestore'
import Station from '../../models/Station'
import {Alert} from 'react-bootstrap'

const stationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  portcode: Yup.string(),
  organizationId: Yup.string().required('Organization / supplier is required'),
})
const stationDB = 'stations'
const organizationDB = 'organizations'

const StationManagement: React.FC = () => {
  const params = useParams()
  const sid = params.id ? params.id.toString() : undefined
  const init: any[] = []
  const [organizations, setOrganizations] = useState(init)

  const initialValues: Station = {
    name: '',
    portcode: '',
    organizationId: '',
  }

  const [data, setData] = useState<Station>(initialValues)
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState({isAlert: false, msg: '', variant: 'primary'})

  const formik = useFormik<Station>({
    initialValues,
    validationSchema: stationSchema,
    onSubmit: (values) => {
      setLoading(true)
      setAlert({isAlert: false, msg: '', variant: 'primary'})
      // values.dateCreated = new Date()
      const updatedData = Object.assign(data, values)

      const seOrg = organizations.find((e) => e.id === updatedData.organizationId)
      if (seOrg) {
        updatedData.organizationName = seOrg.name
        setData(updatedData)
        if (editMode) {
          editStation(updatedData)
        } else {
          addStation(updatedData)
        }
      }
      setLoading(false)
    },
  })

  const updateData = (fieldsToUpdate: Partial<Station>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  useEffect(() => {
    if (sid) {
      setEditMode(true)
      const doDocRef = doc(fbsdb, stationDB, sid)
      onSnapshot(doDocRef, (doc) => {
        const station = doc.data() as any
        station.id = sid
        updateData(station)
        // formik.setValues(usr)
        formik.setValues(data)
      })
    }
    loadOrganizations()
  }, [])

  const addStation = async (updatedData: Station) => {
    try {
      await addDoc(collection(fbsdb, stationDB), {
        name: updatedData.name,
        portcode: updatedData.portcode,
        organizationId: updatedData.organizationId,
        organizationName: updatedData.organizationName,
      })

      setAlert({
        isAlert: true,
        msg: 'New station added!',
        variant: 'success',
      })

      formik.setValues(initialValues)
    } catch (e) {
      console.log(e)
      setAlert({
        isAlert: true,
        msg: 'Failed to add new station.',
        variant: 'danger',
      })
    }

    setLoading(false)
  }

  const editStation = async (updatedData: Station) => {
    try {
      if (sid) {
        await updateDoc(doc(fbsdb, stationDB, sid), {
          name: updatedData.name,
          portcode: updatedData.portcode,
          organizationId: updatedData.organizationId,
          organizationName: updatedData.organizationName,
        })

        setAlert({
          isAlert: true,
          msg: 'Station Profile updated!',
          variant: 'success',
        })
      }
    } catch (e) {
      console.log(e)
      setAlert({
        isAlert: true,
        msg: 'Update station failed.',
        variant: 'danger',
      })
    }

    setLoading(false)
  }

  const loadOrganizations = async () => {
    const q = await getDocs(query(collection(fbsdb, organizationDB)))
    const objList: any[] = q.docs.map((doc: any) => {
      const obj = doc.data() as any
      obj.id = doc.id

      return obj
    })

    setOrganizations(objList)
  }

  return (
    <>
      {alert.isAlert && (
        <Alert key='danger' variant={alert.variant}>
          {alert.msg}{' '}
          <Link className='fw-bolder' to={'/management/stations/list'}>
            Back to station list
          </Link>
        </Alert>
      )}
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{editMode ? 'Edit Station' : 'New Station'}</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Station Name'
                        {...formik.getFieldProps('name')}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.name}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Port Code</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Port Code'
                    {...formik.getFieldProps('portcode')}
                  />
                  {formik.touched.portcode && formik.errors.portcode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.portcode}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Organization / Supplier Group
                </label>

                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg'
                    {...formik.getFieldProps('organizationId')}
                  >
                    <option value=''>Select a supplier Group</option>
                    {organizations.map((org) => (
                      <option key={org.id} value={org.id}>
                        {org.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.organizationId && formik.errors.organizationId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.organizationId}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default StationManagement
