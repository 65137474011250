import {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {fbsdb} from '../../utils/firebase'
import {addDoc, collection, doc, getDocs, getDoc, query, updateDoc} from 'firebase/firestore'
import City from '../../models/City'
import {Alert} from 'react-bootstrap'

const citySchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  stateId: Yup.string().required('State is required'),
})
const stateDB = 'states'
const stateCityDB = 'state_cities'

const StateCityManagement: React.FC = () => {
  const params = useParams()
  const cityId = params.id ? params.id.toString() : undefined
  const init: any[] = []
  const [states, setStates] = useState(init)

  const initialValues: City = {
    name: '',
    stateId: '',
    stateName: '',
  }

  const [data, setData] = useState<City>(initialValues)
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState({isAlert: false, msg: '', variant: 'primary'})

  const formik = useFormik<City>({
    initialValues,
    validationSchema: citySchema,
    onSubmit: (values) => {
      setLoading(true)
      setAlert({isAlert: false, msg: '', variant: 'primary'})
      // values.dateCreated = new Date()
      const updatedData = Object.assign(data, values)

      const seOrg = states.find((e) => e.id === updatedData.stateId)
      if (seOrg) {
        updatedData.stateName = seOrg.name
        setData(updatedData)
        if (editMode) {
          editCity(updatedData)
        } else {
          addCity(updatedData)
        }
      }
      setLoading(false)
    },
  })

  const updateData = (fieldsToUpdate: Partial<City>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  useEffect(() => {
    loadCity()
  }, [])

  const loadCity = async () => {
    if (cityId) {
      setEditMode(true)
      const doDocRef = doc(fbsdb, stateCityDB, cityId)
      const docSnap = await getDoc(doDocRef)

      if (docSnap.exists()) {
        const city = docSnap.data() as any
        city.id = cityId
        updateData(city)
        // formik.setValues(usr)
        formik.setValues(data)
      }
    }

    loadStates()
  }

  const addCity = async (updatedData: City) => {
    try {
      await addDoc(collection(fbsdb, stateCityDB), {
        name: updatedData.name,
        stateId: updatedData.stateId,
        stateName: updatedData.stateName,
      })

      setAlert({
        isAlert: true,
        msg: 'New city added!',
        variant: 'success',
      })

      formik.setValues(initialValues)
    } catch (e) {
      console.log(e)
      setAlert({
        isAlert: true,
        msg: 'Failed to add new city.',
        variant: 'danger',
      })
    }

    setLoading(false)
  }

  const editCity = async (updatedData: City) => {
    try {
      if (cityId) {
        await updateDoc(doc(fbsdb, stateCityDB, cityId), {
          name: updatedData.name,
          stateId: updatedData.stateId,
          stateName: updatedData.stateName,
        })

        setAlert({
          isAlert: true,
          msg: 'City updated!',
          variant: 'success',
        })
      }
    } catch (e) {
      console.log(e)
      setAlert({
        isAlert: true,
        msg: 'Update city failed.',
        variant: 'danger',
      })
    }

    setLoading(false)
  }

  const loadStates = async () => {
    const q = await getDocs(query(collection(fbsdb, stateDB)))
    const objList: any[] = q.docs.map((doc: any) => {
      const obj = doc.data() as any
      obj.id = doc.id

      return obj
    })

    setStates(objList)
  }

  return (
    <>
      {alert.isAlert && (
        <Alert key='danger' variant={alert.variant}>
          {alert.msg}{' '}
          <Link className='fw-bolder' to={'/management/cities/list'}>
            Back to city list
          </Link>
        </Alert>
      )}
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>
              {editMode ? 'Edit City / Town / District' : 'New City / Town / District'}
            </h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Station Name'
                        {...formik.getFieldProps('name')}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.name}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>State</label>

                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg'
                    {...formik.getFieldProps('stateId')}
                  >
                    <option value=''>Select a state</option>
                    {states.map((state) => (
                      <option key={state.id} value={state.id}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.stateId && formik.errors.stateId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.stateId}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default StateCityManagement
