import {addDoc, collection, doc, updateDoc} from 'firebase/firestore'
import {useEffect, useState} from 'react'
import {Button, Col, Form, Modal, Row} from 'react-bootstrap'
import {fbsdb} from '../../utils/firebase'

const organizationDB = 'organizations'
const groupDB = 'groups'

const SupplierGroupModal = (props: any) => {
  const group = props.group
  const sid = props.sid

  const [name, setName] = useState('')
  const [color, setColor] = useState('')
  const [id, setId] = useState('')
  const [supplierId, setSupplierId] = useState('')
  const [modalShow, setModalShow] = useState(false)
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    setSupplierId(sid)
    if (group) {
      setName(group.name)
      setColor(group.color)
      setId(group.id)
      setEditMode(true)
    } else {
      setName('')
      setColor('')
      setId('')
      setEditMode(false)
    }
  }, [group, sid])

  useEffect(() => {
    if (props.modalShow !== null) {
      setModalShow(props.modalShow)
    }
  }, [props.modalShow])

  const nameHandler = (event: any) => {
    setName(event.currentTarget.value)
  }

  const colorHandler = (event: any) => {
    setColor(event.currentTarget.value)
  }

  const modalSaveHandler = async () => {
    if (editMode) {
      const groupDocRef = doc(fbsdb, organizationDB + '/' + supplierId + '/' + groupDB, id)
      const data = {
        name,
        color: color ? color : '',
      }
      await updateDoc(groupDocRef, data)
    } else {
      await addDoc(collection(fbsdb, organizationDB + '/' + supplierId + '/' + groupDB), {
        name,
        color,
        type: 'secondary',
      })
    }

    props.ModalSaveHandler()
  }

  return (
    <Modal show={modalShow} onHide={props.ModalCloseHandler} backdrop='static' keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>
          {editMode && 'Edit Supplier'}
          {!editMode && 'New Supplier'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group as={Row} className='mb-3' controlId='formName'>
            <Form.Label column sm={7}>
              Name
            </Form.Label>
            <Col sm={5}>
              <Form.Control
                type='text'
                placeholder='Name of Supplier'
                value={name}
                onChange={nameHandler}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3' controlId='formColor'>
            <Form.Label column sm={7}>
              Color
            </Form.Label>
            <Col sm={5}>
              <Form.Control
                type='text'
                placeholder='Color Label'
                value={color}
                onChange={colorHandler}
              />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={props.ModalCloseHandler}>
          Close
        </Button>
        <Button variant='warning' onClick={modalSaveHandler}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SupplierGroupModal
