const states = [
  {name: 'Johor', code: 'JHR'},
  {name: 'Kedah', code: 'KDH'},
  {name: 'Kelantan', code: 'KTN'},
  {name: 'Kuala Lumpur', code: 'KUL'},
  {name: 'Labuan', code: 'LBN'},
  {name: 'Malacca', code: 'MLK'},
  {name: 'Negeri Sembilan', code: 'NSN'},
  {name: 'Pahang', code: 'PHG'},
  {name: 'Penang', code: 'PNG'},
  {name: 'Perak', code: 'PRK'},
  {name: 'Perlis', code: 'PLS'},
  {name: 'Putrajaya', code: 'PJY'},
  {name: 'Sabah', code: 'SBH'},
  {name: 'Sarawak', code: 'SWK'},
  {name: 'Selangor', code: 'SGR'},
  {name: 'Terengganu', code: 'TRG'},
]

const capitalizeFirst = (str: string | undefined) => {
  if (str === undefined) {
    return ''
  }

  return str.charAt(0).toUpperCase() + str.slice(1)
}

const minConvertToHourDisplay = (n: number) => {
  var num = n
  var hours = num / 60
  var rhours = Math.floor(hours)
  var minutes = (hours - rhours) * 60
  var rminutes = Math.round(minutes)
  return rhours + 'h ' + rminutes + 'm'
}

const sumDigit = (num: number, sum: number = 0): number => {
  if (num) {
    return sumDigit(Math.floor(num / 10), sum + (num % 10))
  }
  return sum
}

const sumRepeatedly = (num: number) => {
  while (num > 9) {
    num = sumDigit(num)
  }
  return num
}

export {states, capitalizeFirst, minConvertToHourDisplay, sumRepeatedly}
