import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useAuth} from '../auth'
import ShipmentList from './ShipmentList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Shipment',
    path: '/shipments/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ShipmentsPage = () => {
  const {currentUser} = useAuth()

  return (
    <>
      {currentUser && (
        <>
          {(currentUser.role === 'admin' ||
            currentUser.role === 'staff' ||
            currentUser.role === 'manager') && (
            <Routes>
              <Route element={<Outlet />}>
                <Route
                  path='/list'
                  element={
                    <>
                      <PageTitle breadcrumbs={usersBreadcrumbs}>Shipment List</PageTitle>
                      <ShipmentList />
                    </>
                  }
                />
              </Route>
              <Route index element={<Navigate to='/shipments/list' />} />
            </Routes>
          )}
        </>
      )}
    </>
  )
}

export default ShipmentsPage
